// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { YAxis as YReAxis, } from 'recharts';

import { YAxisDefault, } from '../../utils/config';
import { createFieldOrDefault, } from '../../../../logic/object';
import { convertToDuration, } from '../../utils/data';


/**
 * Do not use it as component <YAxis {...config} /> but as a function YAxis(config).
 * Custom components does not work in Recharts.
 */
const YAxis = (config) => {
  const getField = createFieldOrDefault(config, YAxisDefault);

  return (
    <YReAxis
      hide={getField('hidden')}
      axisLine={getField('axisLine')}
      interval="preserveStartEnd"
      tickMargin={16}
      tickFormatter={getField('format') === 'duration' ? convertToDuration : null}
    />
  );
};


export default YAxis;
