import React, { Component, } from 'react';
import {
  shape, string, func, object, number,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { MUTATION_DELETE_TRANSPORTATION, } from '../../gql/mutations';
import { pipe, } from '../../../../logic/utils';
import { RESERVATION_TYPES, } from '../../../../globals';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeFormValidations,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeMultipleValues,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import {
  getReservationTimeToByCarQuantity,
  getReservationTypesOptionsByTransporationType,
  parseTransportationMutationVariables,
} from '../utils';
import { initTransportationForm, customValidationReservationForm, } from '../forms/structure';
import { QUERY_ALL_COMPANIES, } from '../../gql/queries';
import CompanyCreate from '../../../Directory/components/CompanyCreate/CompanyCreate';
import TransportationEditView from './TransportationEditView';
import ModalDelete from '../../../../components/modal/ModalDelete';


class TransportationEditLogic extends Component {
  constructor(props) {
    super(props);

    const {
      translations,
      data: {
        reservation,
        company,
        driver,
        truck,
        trailer,
        extra,
        id,
      },
      rsTransportationConfig,
    } = props;
    const transportationTypeForRS = '1';

    let carCompany = null;
    let destination = null;
    if (reservation.carCompanyId) {
      carCompany = rsTransportationConfig
        .carCompanies
        .find((carCompanyIterator) => carCompanyIterator.id
          === parseInt(reservation.carCompanyId, 10));
    }
    if (reservation.destinationId) {
      destination = rsTransportationConfig
        .destinations
        .find((destinationIterator) => destinationIterator.id
          === parseInt(reservation.destinationId, 10));
    }

    this.state = {
      detailForm: {
        ...initTransportationForm,
        values: {
          ...initTransportationForm.values,
          ...reservation,
          resDate: reservation.resDate ? new Date(reservation.resDate) : null,
          resTimeFrom: reservation.resTimeFrom ? new Date(reservation.resTimeFrom) : null,
          resTimeTo: reservation.resTimeTo ? new Date(reservation.resTimeTo) : null,
          carQuantity: reservation.carQuantity
            ? { id: reservation.carQuantity, name: reservation.carQuantity, } : null,
          carCompany,
          destination,
          ...company,
          ...driver,
          ...truck,
          ...trailer,
          ...extra,
          id,
          languageId: driver.languageId ? {
            id: driver.languageId,
            name: driver.languageName,
          } : null,
        },
      },
      options: {
        reservationTypes: getReservationTypesOptionsByTransporationType(
          reservation.resTransportationType.id,
          transportationTypeForRS,
          translations
        ),
        carQuantity: [ ...Array(10).keys(), ].map((n) => ({ id: `${n + 1}`, name: `${n + 1}`, })),
        transportationTypeForRS,
      },
      modalNewCompany: {
        isOpen: false,
        presetValues: null,
      },
      modalDelete: {
        isOpen: false,
      },
    };
  }


  /**
   * Form - onChange
   */
  handleChangeForm = (name, value) => {
    const { detailForm, options, } = this.state;

    switch (name) {
      // Transportation Type - reset Platform
      case 'resTransportationType': {
        const { translations, } = this.props;
        const reservationTypes = getReservationTypesOptionsByTransporationType(value.id, options.transportationTypeForRS, translations);
        const newDetailForm = changeMultipleValues(detailForm, [
          {
            name,
            value,
          },
          {
            name: 'resPlatform',
            value: null,
          },
          {
            name: 'resReservationType',
            value: null,
          },
          {
            name: 'carQuantity',
            value: null,
          },
          {
            name: 'carCompany',
            value: null,
          },
          {
            name: 'destination',
            value: null,
          },
        ]);
        this.setState((prevState) => ({
          detailForm: newDetailForm,
          options: {
            ...prevState.options,
            reservationTypes,
          },
        }));
        break;
      }

      // Reservation Type - change validation
      case 'resReservationType': {
        const resTypeDay = value && value.id === RESERVATION_TYPES.DAY;
        const resTypeInterval = value && (
          value.id === RESERVATION_TYPES.INTERVAL
          || value.id === RESERVATION_TYPES.INTERVAL_PLATFORM
        );
        const resTypeIntervalPlatform = value && value.id === RESERVATION_TYPES.INTERVAL_PLATFORM;

        const newForm = changeFormValidations(
          detailForm,
          [
            {
              name: 'resPlatform',
              validation: { required: resTypeIntervalPlatform, },
            },
            {
              name: 'resDate',
              validation: { required: resTypeDay, },
            },
            {
              name: 'resTimeFrom',
              validation: { required: resTypeInterval, },
            },
            {
              name: 'resTimeTo',
              validation: { required: resTypeInterval, },
            },
          ],
        );

        const newDetailForm = changeAndValidateInput(newForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        break;
      }

      case 'carQuantity':
      case 'resTimeFrom': {
        const newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });

        if (newDetailForm.values.resTransportationType.id
          === options.transportationTypeForRS
        ) {
          this.computeReservationTimeTo(newDetailForm);
        }
        break;
      }

      // Default
      default: {
        const newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        break;
      }
    }
  }

  computeReservationTimeTo = (detailForm) => {
    if (!detailForm.values.carQuantity) {
      return;
    }
    const { rsTransportationConfig, } = this.props;

    const newDetailForm = changeAndValidateInput(detailForm, 'resTimeTo',
      getReservationTimeToByCarQuantity(
        parseInt(detailForm.values.carQuantity.id, 10),
        rsTransportationConfig.unloadDurations,
        detailForm.values.resTimeFrom,
      ));
    this.setState({ detailForm: newDetailForm, });
  }


  /**
   * Fill form values
   */
  handleFillFormValues = (neValues) => {
    const { detailForm, } = this.state;

    const newDetailForm = changeMultipleValues(detailForm, neValues);
    this.setState({ detailForm: newDetailForm, });
  }


  /**
   * Modal New Company - Close
   */
  closeModalAddNewCompany = () => {
    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: false,
        presetValues: null,
      },
    }));
  }

  /**
   * Modal New Company - Open
   */
  handleAddNewCompany = () => {
    const { detailForm: { values, }, } = this.state;

    const parsedCompanyData = {
      name: values.compName,
      identificationNumber: values.compIdentificationNumber,
      vatNumber: values.compVatNumber,
      street: values.compStreet,
      city: values.compCity,
      zip: values.compZip,
      state: values.compState,
      info: values.compInfo,
      contactPerson: values.compContactPerson,
      email: values.compEmail,
      phoneNumber: values.compPhoneNumber,
    };

    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: true,
        presetValues: parsedCompanyData,
      },
    }));
  }


  /**
   * Modal New Company - Company Added
   */
  handleAddNewCompanyCompleted = () => {
    const { client, } = this.props;

    client.query({
      query: QUERY_ALL_COMPANIES,
      fetchPolicy: 'network-only',
    });
  }


  /**
   * onEdit
   */
  handleEdit = (editMutation) => {
    const { detailForm, detailForm: { values, }, options, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(
      detailForm,
      customValidationReservationForm(options.transportationTypeForRS)
    );
    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const parsedValues = parseTransportationMutationVariables(values);
      editMutation({
        variables: {
          ...parsedValues,
          id: values.id,
          // TODO I don't like this - probably make two queries - monitoring and edit
          isMonitoring: false,
        },
      });
    }
  }


  /**
   * onEdit - Completed
   */
  handleEditComplete = (responseData) => {
    const {
      translations,
      onToggle,
      addNotification,
      onEdited,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onEdited) onEdited(responseData);
  }


  /**
   * onEdit - Error
   */
  handleEditError = (mutationError) => {
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  /**
   * onDelete
   */
  handleToggleDeleteModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalDelete: {
        ...prevState.modalDelete,
        isOpen: !prevState.modalDelete.isOpen,
      },
    }));
  }


  /**
   * onDelete - Completed
   */
  handleDeleteCompleted = (responseData) => {
    const {
      onDeleted,
      onToggle,
    } = this.props;

    onToggle();
    if (onDeleted) onDeleted(responseData);
  }


  render() {
    const {
      detailForm,
      options,
      modalNewCompany,
      modalDelete,
    } = this.state;
    const {
      data, languageId, translations, onToggle, rsTransportationConfig,
    } = this.props;


    return (
      <>
        {modalNewCompany.isOpen && (
          <CompanyCreate
            // data
            modalData={{ isOpen: true, }}
            presetValues={modalNewCompany.presetValues}
            // methods
            onToggle={this.closeModalAddNewCompany}
            onCreated={this.handleAddNewCompanyCompleted}
          />
        )}

        {modalDelete.isOpen && (
          <ModalDelete
            // data
            title={translations.transportation.titleDeleteTransportation}
            text={translations.transportation.textDeleteTransportation}
            mutation={MUTATION_DELETE_TRANSPORTATION}
            variables={{ id: detailForm.values.id, }}
            // methods
            onToggle={this.handleToggleDeleteModal}
            onCompleted={this.handleDeleteCompleted}
          />
        )}

        <TransportationEditView
          // data
          data={data}
          detailForm={detailForm}
          options={options}
          languageId={languageId}
          translations={translations}
          rsTransportationConfig={rsTransportationConfig}
          // methods
          onToggle={onToggle}
          onChangeForm={this.handleChangeForm}
          onEdit={this.handleEdit}
          onEditComplete={this.handleEditComplete}
          onEditError={this.handleEditError}
          onFillFormValues={this.handleFillFormValues}
          onAddNewCompany={this.handleAddNewCompany}
          onDelete={this.handleToggleDeleteModal}
        />

      </>
    );
  }
}


TransportationEditLogic.propTypes = {
  // data
  data: shape({
    id: string.isRequired,
    reservation: shape({
      resNo: string.isRequired,
      resTransportationType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }).isRequired,
      resReservationType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }).isRequired,
      resPlatform: shape({
        id: string.isRequired,
        name: string.isRequired,
      }),
      resTimeFrom: string,
      resTimeTo: string,
      resDate: string,
    }).isRequired,
    company: shape({
      compName: string.isRequired,
      compIdentificationNumber: string.isRequired,
      compVatNumber: string.isRequired,
      compStreet: string.isRequired,
      compCity: string.isRequired,
      compZip: string.isRequired,
      compState: string.isRequired,
      compContactPerson: string.isRequired,
      compEmail: string.isRequired,
      compPhoneNumber: string.isRequired,
    }).isRequired,
    driver: shape({
      driverName: string.isRequired,
      driverPhoneNumber: string.isRequired,
      languageId: number.isRequired,
    }).isRequired,
    truck: shape({
      truckRegistrationNumber: string.isRequired,
    }).isRequired,
    trailer: shape({
      trailerRegistrationNumber: string.isRequired,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  client: object.isRequired,
  rsTransportationConfig: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onDeleted: func,
  onEdited: func,
};

TransportationEditLogic.defaultProps = {
  onDeleted: undefined,
  onEdited: undefined,
};


export default pipe(
  withNotifications,
  withApollo,
)(TransportationEditLogic);
