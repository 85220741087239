import React, { Component, } from 'react';
import {
  bool, func, shape, number, arrayOf,
} from 'prop-types';

import Button from '../../atoms/Button/Button';

import StyledPagination from './styles/StyledPagination';
import InputSelect from '../../atoms/InputSelect/InputSelect';


class Pagination extends Component {
  getCurrentPage = () => {
    const { dataFilter: { offset, limit, }, } = this.props;

    if (offset === 0 && limit === 0) return 1;

    return Math.trunc(offset / limit) + 1;
  }

  getLastPage = () => {
    const { dataFilter: { count, limit, }, } = this.props;
    const offsetLast = count - 1;

    return Math.trunc(offsetLast / limit) + 1;
  }

  handleOnClickPage = (page) => {
    const { onChangePage, dataFilter: { limit, }, } = this.props;

    onChangePage('offset', (page - 1) * limit);
  }

  render() {
    const {
      loading, limits, dataFilter: { limit, }, onChangePage,
    } = this.props;

    const currentPage = this.getCurrentPage();
    const lastPage = this.getLastPage();
    const isDisabled = loading;

    return (
      <StyledPagination>
        {currentPage > 3 && (
          <Button
            size="sm"
            color="tertiary"
            outline
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(1)}
          >
            1
          </Button>
        )}

        {currentPage > 4 && (
          <span className="pagination--separator">...</span>
        )}

        {currentPage > 2 && (
          <Button
            size="sm"
            color="tertiary"
            outline
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage - 2)}
          >
            {currentPage - 2}
          </Button>
        )}

        {currentPage > 1 && (
          <Button
            size="sm"
            color="tertiary"
            outline
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage - 1)}
          >
            {currentPage - 1}
          </Button>
        )}

        <Button
          className="pagination--actual"
          size="sm"
          color="tertiary"
          onClick={() => {}}
          disabled={isDisabled}
        >
          {currentPage}
        </Button>

        {(lastPage > currentPage) && (
          <Button
            size="sm"
            color="tertiary"
            outline
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage + 1)}
          >
            {currentPage + 1}
          </Button>
        )}

        {(lastPage > currentPage + 1) && (
          <Button
            size="sm"
            color="tertiary"
            outline
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(currentPage + 2)}
          >
            {currentPage + 2}
          </Button>
        )}

        {(lastPage > currentPage + 3) && (
          <span className="pagination--separator">...</span>
        )}

        {(lastPage > currentPage + 2) && (
          <Button
            size="sm"
            color="tertiary"
            outline
            disabled={isDisabled}
            onClick={() => this.handleOnClickPage(lastPage)}
          >
            {lastPage}
          </Button>
        )}

        {limits && limits.length > 0 && (
          <InputSelect
            className="limits--select"
            value={{ value: limit, }}
            options={limits.map((value) => ({ value, }))}
            onChange={(l) => onChangePage('limit', l.value)}
            getOptionLabel={(l) => l.value}
            getOptionValue={(l) => l.value}
            isLoading={loading}
          />
        )}
      </StyledPagination>
    );
  }
}


Pagination.propTypes = {
  dataFilter: shape({
    limit: number.isRequired,
    offset: number.isRequired,
    count: number.isRequired,
  }),
  loading: bool.isRequired,
  onChangePage: func.isRequired,
  limits: arrayOf(number.isRequired),
};


Pagination.defaultProps = {
  dataFilter: {
    limit: 0,
    offset: 0,
    count: 0,
  },
  limits: undefined,
};


export default Pagination;
