import React from 'react';
import {
  func, object, shape, bool, string,
} from 'prop-types';
import { Query, Mutation, } from 'react-apollo';

import { QUERY_MOVE_PLATFORMS, } from '../../../gql/queries';
import { MUTATION_MOVE_TRANSPORTATION, } from '../../../gql/mutations';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import InputSelect from '../../../../../atoms/InputSelect/InputSelect';
import FormBackground from '../../../../../components/Form/FormBackground';
import FormError from '../../../../../components/Form/FormError';
import FormRow from '../../../../../components/Form/FormRow';


const ModalMoveView = ({
  // data
  platformId,
  moveForm: {
    isValid,
    form,
    values,
  },
  languageId,
  translations,
  // methods
  onToggle,
  onChangeMoveForm,
  onMove,
  onMoveCompleted,
  onMoveError,
}) => (
  <FormBackground>

    <Row formGroup>
      <Col>
        {translations.centralAppStorekeeper.textModalMove}
      </Col>
    </Row>

    <FormRow
      label={translations.form.platform}
      input={form.platform}
    >
      <Query
        query={QUERY_MOVE_PLATFORMS}
        variables={{
          languageId,
          platformId,
        }}
      >
        {({ loading, error, data, }) => (
          <InputSelect
            options={(data && data.fetchStoreKeeperMovePossiblePlatforms)
              ? data.fetchStoreKeeperMovePossiblePlatforms
              : []
            }
            value={values.platform}
            onChange={(newValue) => onChangeMoveForm(form.platform.name, newValue)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            loading={loading}
            error={error}
            placeholder={translations.form.placeholderModalForwardPlatform}
          />
        )}
      </Query>
    </FormRow>

    <Mutation
      mutation={MUTATION_MOVE_TRANSPORTATION}
      onCompleted={onMoveCompleted}
      onError={onMoveError}
    >
      {(mutationMove, { loading, error, }) => (
        <>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onToggle}
              >
                {translations.common.close}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                className="buttonGrp--right"
                color="primary"
                disabled={!isValid}
                onClick={() => onMove(mutationMove)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnMove}
              </ButtonLoader>
            </Col>
          </Row>
        </>
      )}
    </Mutation>

  </FormBackground>
);


ModalMoveView.propTypes = {
  // data
  platformId: string.isRequired,
  moveForm: shape({
    isValid: bool.isRequired,
    form: shape({
      platform: object.isRequired,
    }).isRequired,
    values: shape({
      platform: object,
    }).isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeMoveForm: func.isRequired,
  onMove: func.isRequired,
  onMoveCompleted: func.isRequired,
  onMoveError: func.isRequired,
};


export default ModalMoveView;
