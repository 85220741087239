import React from 'react';
import {
  oneOfType, node, object, func, arrayOf, string,
} from 'prop-types';

import { GATEHOUSE_TABS, } from '../pages/GatehousePage';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import Card from '../../../atoms/Card/Card';
import TabPanel from '../../../atoms/TabPanel/TabPanel';
import Tab from '../../../atoms/TabPanel/Tab';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import PartError from '../../../components/Parts/PartError';
import HeadingWihButtons from '../../../atoms/HeadingWihButtons/HeadingWihButtons';


const GatehouseView = function ({
  // data
  activeTab,
  translations,
  children,
  // methods
  onChangePage,
}) {
  return <>
    <Breadcrumb>
      <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
      <BreadcrumbItem>{translations.common.gatehouse}</BreadcrumbItem>
    </Breadcrumb>

    <Card>

      <HeadingWihButtons>
        <h2>{translations.common.gatehouse}</h2>
      </HeadingWihButtons>

      <TabPanel>
        <Tab
          name={GATEHOUSE_TABS.HISTORY}
          activeTab={activeTab}
          onClick={onChangePage}
          style={{ width: '8rem', }}
        >
          {translations.gatehouse.tabHistory}
        </Tab>
      </TabPanel>

      <ErrorBoundary
        renderError={() => <PartError />}
      >
        {children}
      </ErrorBoundary>
    </Card>
  </>;
};


GatehouseView.propTypes = {
  // data
  activeTab: string.isRequired,
  translations: object.isRequired,
  children: oneOfType([ node, arrayOf(node), ]).isRequired,
  // methods
  onChangePage: func.isRequired,
};


export default GatehouseView;
