import React from 'react';
import { func, object, string, } from 'prop-types';

import { Query, } from 'react-apollo';
import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import { withUser, } from '../../../../logic/auth/withUser';
import { pipe, } from '../../../../logic/utils';
import Modal from '../../../../atoms/Modal/Modal';
import TransportationCreateLogic from './TransportationCreateLogic';
import { QUERY_RS_TRANSPORTATION_CONFIG, } from '../../gql/queries';


const TransportationCreate = ({
  // data
  translations,
  languageId,
  resources,
  // methods
  onToggle,
  onCreated,
  // custom
  extraCreateButton,
  onCreatedExtraCreateBtn,
}) => (
  <Modal
    title={translations.transportation.createModalTransportationTitle}
    isOpen
    size="XL"
    onClose={onToggle}
    disablePadding
  >
    <Query
      query={QUERY_RS_TRANSPORTATION_CONFIG}
    >
      {({ loading, error, data, }) => {
        if (loading || error) {
          return false;
        }
        return (
          <TransportationCreateLogic
            // data
            languageId={languageId}
            translations={translations}
            resources={resources}
            rsTransportationConfig={data.fetchConfigForTransportation}
            // methods
            onToggle={onToggle}
            onCreated={onCreated}
            // custom
            extraCreateButton={extraCreateButton}
            onCreatedExtraCreateBtn={onCreatedExtraCreateBtn}
          />
        );
      }}
    </Query>
  </Modal>
);


TransportationCreate.propTypes = {
  extraCreateButton: object,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  onToggle: func.isRequired,
  onCreated: func,
  onCreatedExtraCreateBtn: func,
};

TransportationCreate.defaultProps = {
  extraCreateButton: undefined,
  onCreated: undefined,
  onCreatedExtraCreateBtn: undefined,
};


export default pipe(
  withTranslations(TRANSLATIONS_TRANSPORTATION),
  withUser,
)(TransportationCreate);
