import React, { Fragment, } from 'react';
import {
  shape, string, func, object, bool,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';


const VehicleGroupFrom = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.name}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.freeEntrance}
      input={form.freeEntrance}
    >

      <InputCheckbox
        value={values.freeEntrance}
        onChange={(checked) => onChange(form.freeEntrance.name, checked)}
        text={translations.form.freeEntranceText}
      />

    </FormRow>


    <FormRow
      label={translations.form.freeExit}
      input={form.freeExit}
    >

      <InputCheckbox
        value={values.freeExit}
        onChange={(checked) => onChange(form.freeExit.name, checked)}
        text={translations.form.freeExitText}
      />

    </FormRow>


    <FormRow
      label={translations.form.blacklist}
      input={form.blacklist}
    >

      <InputCheckbox
        value={values.blacklist}
        onChange={(checked) => onChange(form.blacklist.name, checked)}
        text={translations.form.blacklistText}
      />

    </FormRow>


    <FormRow
      label={translations.form.customs}
      input={form.customs}
    >

      <InputCheckbox
        value={values.customs}
        onChange={(checked) => onChange(form.customs.name, checked)}
        text={translations.form.customsText}
      />

    </FormRow>

    <FormRow
      label={translations.form.internalTransport}
      input={form.internalTransport}
    >

      <InputCheckbox
        value={values.internalTransport}
        onChange={(checked) => onChange(form.internalTransport.name, checked)}
        text={translations.form.internalTransportText}
      />

    </FormRow>

    <FormRow
      label={translations.form.registeredTransport}
      input={form.registeredTransport}
    >

      <InputCheckbox
        value={values.registeredTransport}
        onChange={(checked) => onChange(form.registeredTransport.name, checked)}
        text={translations.form.registeredTransportText}
      />

    </FormRow>

    <FormRow
      label={translations.form.internalTraffic}
      input={form.internalTraffic}
    >

      <InputCheckbox
        value={values.internalTraffic}
        onChange={(checked) => onChange(form.internalTraffic.name, checked)}
        text={translations.form.internalTrafficText}
      />

    </FormRow>


  </Fragment>
);


VehicleGroupFrom.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    freeEntrance: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    freeExit: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    blacklist: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    customs: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    internalTransport: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    registeredTransport: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    internalTraffic: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    freeEntrance: bool,
    freeExit: bool,
    blacklist: bool,
    customs: bool,
    internalTransport: bool,
    registeredTransport: bool,
    internalTraffic: bool,
  }).isRequired,
  onChange: func.isRequired,
};


export default VehicleGroupFrom;
