import { INPUT_TYPE, emailRegex, } from 'sofair-form-validation';

import { initForm, initInput, } from '../../../logic/form/common';
import { regexEmail, } from '../../../logic/form/regexes';


export const initRoleForm = {
  ...initForm,
  form: {
    name: initInput({
      name: 'name',
      validation: {
        required: true,
        min: 2,
        max: 255,
      },
    }),
  },
  values: {
    name: '',
  },
};


export const initUserForm = {
  ...initForm,
  form: {
    username: initInput({
      name: 'username',
      validation: {
        required: true,
        min: 2,
        max: 255,
      },
    }),
    password: initInput({
      name: 'password',
      validation: {
        required: true,
        min: 2,
        max: 255,
      },
    }),
    email: initInput({
      name: 'email',
      validation: {
        regex: regexEmail,
      },
    }),
    chip: initInput({
      name: 'chip',
    }),
    pin: initInput({
      name: 'pin',
    }),
    roles: initInput({
      name: 'roles',
      type: INPUT_TYPE.MULTISELECT,
      validation: {
        required: true,
      },
    }),
  },
  values: {
    username: '',
    password: '',
    chip: '',
    pin: '',
    email: '',
    roles: [],
  },
};


export const initUserUpdateForm = {
  ...initForm,
  form: {
    username: initInput({
      name: 'username',
      validation: {
        required: true,
        min: 2,
        max: 255,
      },
    }),
    password: initInput({
      name: 'password',
      validation: {
        required: false,
        min: 2,
        max: 255,
      },
    }),
    chip: initInput({
      name: 'chip',
    }),
    pin: initInput({
      name: 'pin',
    }),
    email: initInput({
      name: 'email',
      validation: {
        regex: regexEmail,
      },
    }),
    roles: initInput({
      name: 'roles',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
  },
  values: {
    username: '',
    password: '',
    chip: '',
    pin: '',
    email: '',
    roles: [],
  },
};
