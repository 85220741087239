import React, { Component, } from 'react';
import { Query, } from 'react-apollo';
import { func, } from 'prop-types';

import '../components/styles/Reporting.css';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageView from '../../../atoms/PageView/PageView';
import ReportUserGroupsSettings from '../components/ReportUserGroupsSettings';
import { QUERY_REPORT_USER_GROUP_SETTINGS, } from '../gql/queries';
import { client, } from '../../../logic/graphql/apollo';
import { withNotifications, } from '../../../logic/notifications/withNotifications';
import {
  MUTATION_UPDATE_REPORT_USER_GROUP,
  MUTATION_CREATE_REPORT_USER_GROUP,
  MUTATION_DELETE_REPORT_USER_GROUP,
} from '../gql/mutations';
import FillNameModal from '../components/FillNameModal';
import ModalDelete from '../../../components/modal/ModalDelete';


class ReportingAclPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReportUserGroup: null,
      createModal: {
        isOpen: false,
      },
      deleteModal: {
        isOpen: false,
        id: null,
      },
    };
  }

  addNotification = (status, title, text = '') => {
    const { addNotification, } = this.props;
    addNotification({ status, title, text, });
  }

  handleSelectReportUserGroup = (reportUserGroupId, allReportUserGroups) => {
    this.setState(
      { selectedReportUserGroup: allReportUserGroups.find((r) => r.id === reportUserGroupId), }
    );
  }

  handleToggleCreateModal = () => {
    this.setState((prevState) => ({
      createModal: {
        isOpen: !prevState.createModal.isOpen,
      },
    }));
  }

  handleCreate = (name, refetch) => {
    if (!name) {
      this.addNotification('error', 'Zadejte název');
      return;
    }

    client.mutate({
      mutation: MUTATION_CREATE_REPORT_USER_GROUP,
      variables: {
        reportUserGroup: {
          name, reportIds: [], userIds: [], transportationTypeIds: [],
        },
      },
    })
      .then((result) => {
        this.addNotification('success', 'Uloženo');
        this.handleToggleCreateModal();
        refetch();
      })
      .catch((err) => {
        this.addNotification('error', 'Chyba', err.message);
      });
  }

  handleUpdate = (id, reportUserGroup, refetch) => {
    client.mutate({
      mutation: MUTATION_UPDATE_REPORT_USER_GROUP,
      variables: { id, reportUserGroup, },
    })
      .then((result) => {
        this.addNotification('success', 'Uloženo');
        refetch();
        this.setState({ selectedReportUserGroup: null, });
      })
      .catch((err) => {
        this.addNotification('error', 'Chyba', err.message);
      });
  }

  handleToggleDeleteModal = (id) => {
    this.setState((prevState) => ({
      deleteModal: {
        id,
        isOpen: !prevState.deleteModal.isOpen,
      },
    }));
  }

  handleDeleteCompleted = (refetch) => {
    refetch();
    this.setState({ selectedReportUserGroup: null, });
    this.addNotification('success', 'Smazáno');
  }

  render() {
    const { createModal, deleteModal, selectedReportUserGroup, } = this.state;

    return (
      <PageView>

        <Breadcrumb>
          <BreadcrumbItem to="/">Domů</BreadcrumbItem>
          <BreadcrumbItem>Nastavení reportů</BreadcrumbItem>
        </Breadcrumb>

        <Query query={QUERY_REPORT_USER_GROUP_SETTINGS}>
          {({ data, loading, refetch, }) => {
            if (loading) {
              return <></>;
            }

            return (
              <>
                {createModal.isOpen && (
                  <FillNameModal
                    title="Zadejte název"
                    inputLabel="Název"
                    onClose={this.handleToggleCreateModal}
                    onCreate={(...args) => this.handleCreate(...args, refetch)}
                  />
                )}

                {deleteModal.isOpen && (
                  <ModalDelete
                    // data
                    title="Smazat"
                    text="Opravdu smazat?"
                    mutation={MUTATION_DELETE_REPORT_USER_GROUP}
                    variables={{ reportUserGroupId: deleteModal.id, }}
                    // methods
                    onToggle={this.handleToggleDeleteModal}
                    onCompleted={() => this.handleDeleteCompleted(refetch)}
                  />
                )}

                <ReportUserGroupsSettings
                  reportUserGroups={data.getReportUserGroups}
                  reports={data.getAllReports}
                  users={data.fetchUsersForReporting}
                  transportationTypes={data.fetchAllTransportationTypes}
                  selectedReportUserGroup={selectedReportUserGroup}
                  onCreate={this.handleToggleCreateModal}
                  onUpdate={(...args) => this.handleUpdate(...args, refetch)}
                  onDelete={this.handleToggleDeleteModal}
                  onSelect={(...args) => this.handleSelectReportUserGroup(...args, data.getReportUserGroups)}
                />
              </>
            );
          }}
        </Query>

      </PageView>
    );
  }
}

ReportingAclPage.propTypes = {
  addNotification: func.isRequired,
};


export default withNotifications(ReportingAclPage);
