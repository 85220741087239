import { INPUT_TYPE, } from 'sofair-form-validation';
import { initForm, initInput, } from '../../../../logic/form/common';
import { regexEmail, regexPhone, } from '../../../../logic/form/regexes';


/**
 * Truck
 */
export const initTruckForm = {
  detailForm: {
    ...initForm,
    form: {
      registrationNumber: initInput({
        name: 'registrationNumber',
        validation: {
          required: true,
          min: 3,
          max: 20,
        },
      }),
      vehicleGroup: initInput({
        name: 'vehicleGroup',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
      driver: initInput({
        name: 'driver',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: false,
        },
      }),
    },
    values: {
      registrationNumber: '',
      vehicleGroup: null,
      driver: null,
    },
  },
};


/**
 * Driver
 */
export const initDriverForm = {
  detailForm: {
    ...initForm,
    form: {
      name: initInput({
        name: 'name',
        validation: {
          required: true,
          min: 2,
          max: 255,
        },
      }),
      phoneNumber: initInput({
        name: 'phoneNumber',
        validation: {
          regex: regexPhone,
        },
      }),
      info: initInput({
        name: 'info',
      }),
      trucks: initInput({
        name: 'trucks',
        type: INPUT_TYPE.MULTISELECT,
      }),
    },
    values: {
      name: '',
      phoneNumber: '',
      info: '',
      trucks: [],
    },
  },
};


/**
 * Company
 */
export const initCompanyForm = {
  detailForm: {
    ...initForm,
    form: {
      name: initInput({
        name: 'name',
        validation: {
          required: true,
          min: 2,
          max: 255,
        },
      }),
      identificationNumber: initInput({
        name: 'identificationNumber',
      }),
      vatNumber: initInput({
        name: 'vatNumber',
      }),
      street: initInput({
        name: 'street',
      }),
      city: initInput({
        name: 'city',
      }),
      zip: initInput({
        name: 'zip',
      }),
      state: initInput({
        name: 'state',
      }),
      info: initInput({
        name: 'info',
      }),
      contactPerson: initInput({
        name: 'contactPerson',
      }),
      email: initInput({
        name: 'email',
        validation: {
          regex: regexEmail,
        },
      }),
      phoneNumber: initInput({
        name: 'phoneNumber',
        validation: {
          regex: regexPhone,
        },
      }),
    },
    values: {
      name: '',
      identificationNumber: '',
      vatNumber: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      info: '',
      contactPerson: '',
      email: '',
      phoneNumber: '',
    },
  },
};


/**
 * Vehicle Group
 */
export const initVehicleGroupForm = {
  detailForm: {
    ...initForm,
    form: {
      name: initInput({
        name: 'name',
        validation: {
          required: true,
          min: 3,
          max: 40,
        },
      }),
      freeEntrance: initInput({
        name: 'freeEntrance',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
      freeExit: initInput({
        name: 'freeExit',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
      blacklist: initInput({
        name: 'blacklist',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
      customs: initInput({
        name: 'customs',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
      internalTransport: initInput({
        name: 'internalTransport',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
      registeredTransport: initInput({
        name: 'registeredTransport',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
      internalTraffic: initInput({
        name: 'internalTraffic',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
    },
    values: {
      name: '',
      freeEntrance: null,
      freeExit: null,
      blacklist: null,
      customs: null,
      internalTransport: null,
      registeredTransport: null,
      internalTraffic: null,
    },
  },
};
