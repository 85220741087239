import React, { Component, } from 'react';
import {
  string, func, object, arrayOf, bool,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initTransportationForm, } from '../forms/structure';
import TransportationDetailView from './TransportationDetailView';
import { parseTransportationDetailMutationVariables, } from '../utils';


class TransportationDetailLogic extends Component {
  constructor(props) {
    super(props);

    const {
      detail: {
        extra,
        id,
      },
    } = props;

    this.state = {
      detailForm: {
        ...initTransportationForm,
        values: {
          ...extra,
          id,
        },
      },
    };
  }


  /**
   * Form - onChange
   */
  handleChangeForm = (name, value) => {
    const { detailForm, } = this.state;

    switch (name) {
      // Default
      default: {
        const newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        break;
      }
    }
  }

  /**
   * onEdit
   */
  handleEdit = (editMutation) => {
    const { detailForm: { values, }, } = this.state;
    const parsedValues = parseTransportationDetailMutationVariables(values);
    editMutation({
      variables: {
        ...parsedValues,
        id: values.id,
      },
    });
  }


  /**
   * onEdit - Completed
   */
  handleEditComplete = (responseData) => {
    const {
      translations,
      onToggle,
      addNotification,
      onEdited,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onEdited) onEdited(responseData);
  }


  /**
   * onEdit - Error
   */
  handleEditError = (mutationError) => {
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }

  render() {
    const {
      detailForm,
    } = this.state;
    const {
      // data
      detail,
      tabs,
      transportationId,
      languageId,
      translations,
      rsTransportationConfig,
      fromClearing,
      // methods
      onToggle,
    } = this.props;

    return (
      <>
        <TransportationDetailView
          // data
          transportationId={transportationId}
          detail={detail}
          tabs={tabs}
          rsTransportationConfig={rsTransportationConfig}
          detailForm={detailForm}
          languageId={languageId}
          translations={translations}
          fromClearing={fromClearing}
          // methods
          onToggle={onToggle}
          onChangeForm={this.handleChangeForm}
          onEdit={this.handleEdit}
          onEditComplete={this.handleEditComplete}
          onEditError={this.handleEditError}
        />

      </>
    );
  }
}


TransportationDetailLogic.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  translations: object.isRequired,
  rsTransportationConfig: object.isRequired,
  fromClearing: bool,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onEdited: func,
};

TransportationDetailLogic.defaultProps = {
  fromClearing: false,
  onEdited: () => {},
};


export default pipe(
  withNotifications,
  withApollo,
)(TransportationDetailLogic);
