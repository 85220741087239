import React, { Component, } from 'react';
import { string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_STOREKEEPER_QUEUE, GET_STOREKEEPER_QUEUE_DATA, } from '../../gql/queries';
import Card from '../../../../atoms/Card/Card';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import QueueView from './QueueView';
import ModalCall from './ModalCall/ModalCall';
import TransportationDetail from '../../../Transportation/components/TransportationDetail/TransportationDetail';

import StyledQueue from './styles/StyledQueue';


export const QUEUE_TABS = {
  ALL: 'all',
  PLATFORM: 'platform',
};


const initState = {
  modalCall: {
    isOpen: false,
    transportation: null,
  },
  detailModal: {
    isOpen: false,
    transportation: null,
  },
};


class Queue extends Component {
  state = { ...initState, }


  /**
   * onCall
   */
  handleCall = (transportation = null) => {
    if (!transportation) return;

    this.setState({
      modalCall: {
        isOpen: true,
        transportation,
      },
    });
  }

  /**
   * onCall - Close
   */
  handleCloseModalCall = () => {
    this.setState({
      modalCall: initState.modalCall,
    });
  }


  /**
   * onDetail
   */
  handleDetail = (transportation) => {
    if (!transportation) return;

    this.setState({
      detailModal: {
        isOpen: true,
        transportation,
      },
    });
  }

  /**
   * onDetail - Close
   */
  handleDetailClose = () => {
    this.setState({
      detailModal: initState.detailModal,
    });
  }


  render() {
    const { modalCall, detailModal, } = this.state;
    const {
      languageId,
      translations,
      translationsCommon,
    } = this.props;

    return (
      <StyledQueue>
        <Card>

          {modalCall.isOpen && (
            <ModalCall
              // data
              languageId={languageId}
              translations={translations}
              transportation={modalCall.transportation}
              // functions
              onToggle={this.handleCloseModalCall}
            />
          )}

          {detailModal.isOpen && (
            <TransportationDetail
              // data
              transportationId={detailModal.transportation.id}
              fromClearing
              // functions
              onToggle={this.handleDetailClose}
            />
          )}

          <h4>
            {translations.centralAppStorekeeper.titleQueue}
          </h4>

          <Query
            query={QUERY_STOREKEEPER_QUEUE}
            variables={{ languageId, }}
            fetchPolicy="cache-and-network"
          >
            {({
              loading, error, data, subscribeToMore,
            }) => {
              if (loading) {
                return <PartLoading />;
              }
              if (error || !data.fetchCentralQueueTransportation) {
                return <PartError error={error} />;
              }
              return (
                <Query query={GET_STOREKEEPER_QUEUE_DATA}>
                  {({ data: { storekeeperQueue, }, }) => (
                    <QueueView
                      // data
                      queue={data.fetchCentralQueueTransportation}
                      {...storekeeperQueue}
                      languageId={languageId}
                      translations={translations}
                      translationsCommon={translationsCommon}
                      // functions
                      onCall={this.handleCall}
                      onDetail={this.handleDetail}
                      subscribeToMore={subscribeToMore}
                    />
                  )}
                </Query>
              );
            }}
          </Query>

        </Card>
      </StyledQueue>
    );
  }
}


Queue.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};


export default Queue;
