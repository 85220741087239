import React from 'react';
import { Query, } from 'react-apollo';
import {
  string, func, object, bool,
} from 'prop-types';

import { QUERY_RS_TRANSPORTATION_CONFIG, QUERY_TRANSPORTATION_DETAIL_ADMIN, } from '../../gql/queries';
import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import Modal from '../../../../atoms/Modal/Modal';
import TransportationDetailLogic from './TransportationDetailLogic';


const TransportationDetail = ({
  // data
  transportationId,
  translations,
  languageId,
  fromClearing,
  // methods
  onToggle,
}) => (
  <Modal
    title={translations.transportation.detailTransportationModalTitle}
    isOpen
    size="XL"
    onClose={onToggle}
  >
    <Query
      query={QUERY_TRANSPORTATION_DETAIL_ADMIN}
      variables={{
        id: transportationId,
        languageId: languageId === null ? undefined : languageId,
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <ModalLoadingPart
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }

        if (
          error
          || !data
          || !data.fetchOneTransportation
          || !data.fetchTransportationActionsTabsConfiguration
        ) {
          return (
            <ModalErrorPart
              error={error}
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }

        return (
          <Query query={QUERY_RS_TRANSPORTATION_CONFIG}>
            {(queryRsTransportationConfigResult) => {
              if (queryRsTransportationConfigResult.loading
                || queryRsTransportationConfigResult.error
              ) {
                return (<div />);
              }
              return (
                <TransportationDetailLogic
                  // data
                  transportationId={transportationId}
                  detail={data.fetchOneTransportation}
                  tabs={data.fetchTransportationActionsTabsConfiguration}
                  rsTransportationConfig={queryRsTransportationConfigResult.data.fetchConfigForTransportation}
                  languageId={languageId}
                  translations={translations}
                  fromClearing={fromClearing}
                  // methods
                  onToggle={onToggle}
                />
              );
            }}
          </Query>
        );
      }}
    </Query>
  </Modal>
);


TransportationDetail.propTypes = {
  // data
  transportationId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  fromClearing: bool,
  // methods
  onToggle: func.isRequired,
};

TransportationDetail.defaultProps = {
  fromClearing: false,
};


export default withTranslations(TRANSLATIONS_TRANSPORTATION)(TransportationDetail);
