import gql from 'graphql-tag';


/**
 * Platform
 */
export const SUBSCRIPTION_PLATFORM = gql`
  subscription StoreKeeperPlatform($platformId: ID!, $languageId: ID!) {
    storeKeeperPlatform(platformId: $platformId, languageId: $languageId) {
      id
      name
      opened
      capacity
      clearance {
        id
        lastCall
        transportationId
        transportationTypeId
        platformId
        platformName
        transportationTypeName
        no
        truckRegistration
        trailerRegistration
        state
        startTime
        endTime
        genericFormId
        customs
        driverName
        note
        notArrivedInLimit
      }
    }
  }
`;


/**
 * Queue
 */
export const SUBSCRIPTION_STOREKEEPER_QUEUE_ADD = gql`
  subscription AddTransportationToCentralAppQueue($languageId: ID!) {
    addTransportationToCentralAppQueue(languageId: $languageId) {
      id
      truckRegistration
      trailerRegistration
      driverName
      driverPhone
      no
      date
      timeFrom
      timeTo
      transportationTypeName
      reservationTypeName
      reservationTypeId
      clearanceState
      actionType
      platformName
      platformId
      possiblePlatforms
      customs
      note
      serviceType
      blacklist
      notArrivedInLimit
    }
  }
`;

export const SUBSCRIPTION_STOREKEEPER_QUEUE_UPDATE = gql`
  subscription UpdateTransportationInCentralAppQueue($languageId: ID!) {
    updateTransportationInCentralAppQueue(languageId: $languageId) {
      id
      truckRegistration
      trailerRegistration
      driverName
      driverPhone
      no
      date
      timeFrom
      timeTo
      transportationTypeName
      reservationTypeName
      reservationTypeId
      clearanceState
      actionType
      platformName
      platformId
      possiblePlatforms
      customs
      note
      serviceType
      blacklist
      notArrivedInLimit
    }
  }
`;

export const SUBSCRIPTION_STOREKEEPER_QUEUE_REMOVE = gql`
  subscription RemoveTransportationFromCentralAppQueue {
    removeTransportationFromCentralAppQueue {
      id
    }
  }
`;
