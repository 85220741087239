import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import { QUERY_DD_VEHICLE_GROUPS, QUERY_DD_DRIVERS, } from '../../gql/queries';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';


const TruckFrom = ({
  form,
  translations,
  languageId,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.truckRegistrationNumber}
      input={form.registrationNumber}
    >
      <Input
        type="text"
        value={values.registrationNumber}
        onChange={(e) => onChange(form.registrationNumber.name, e.target.value)}
        status={form.registrationNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.vehicleGroupName}
      input={form.vehicleGroup}
    >
      <Query
        query={QUERY_DD_VEHICLE_GROUPS}
        variables={{ languageId, }}
      >
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.vehicleGroup}
            options={
              (data && data.fetchAllVehicleGroups)
                ? data.fetchAllVehicleGroups
                : []
            }
            onChange={(option) => onChange(form.vehicleGroup.name, option)}
            status={form.vehicleGroup.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isClearable
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

    <FormRow
      label={translations.form.driver}
      input={form.driver}
    >
      <Query
        query={QUERY_DD_DRIVERS}
        variables={{ languageId, }}
      >
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.driver}
            options={
              (data && data.fetchAllDrivers)
                ? [ { id: null, name: `-- ${translations.form.notSelected} --`, }, ].concat(data.fetchAllDrivers)
                : []
            }
            onChange={(option) => onChange(form.driver.name, option)}
            status={form.driver.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isClearable
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

  </Fragment>
);


TruckFrom.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  form: shape({
    registrationNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    vehicleGroup: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    driver: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    registrationNumber: string,
    vehicleGroup: object,
    driver: object,
  }).isRequired,
  onChange: func.isRequired,
};


export default TruckFrom;
