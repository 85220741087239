import React, { Component, } from 'react';
import {
  arrayOf, func, string, object,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_CHANGE_TAB_STOREKEEPER_QUEUE, } from '../../gql/mutations';
import {
  SUBSCRIPTION_STOREKEEPER_QUEUE_ADD,
  SUBSCRIPTION_STOREKEEPER_QUEUE_UPDATE,
  SUBSCRIPTION_STOREKEEPER_QUEUE_REMOVE,
} from '../../gql/subscriptions';
import { QUEUE_TABS, } from './Queue';
import WizardPanel from '../../../../atoms/Wizard/WizardPanel';
import WizardTab from '../../../../atoms/Wizard/WizardTab';
import QueueAll from './QueueAll';
import QueuePlatforms from './QueuePlatforms';


class QueueView extends Component {
  constructor(props) {
    super(props);

    this.unsubscribeAdd = null;
    this.unsubscribeUpdate = null;
    this.unsubscribeRemove = null;
  }


  componentDidMount() {
    const { subscribeToMore, languageId, } = this.props;

    this.unsubscribeAdd = subscribeToMore({
      document: SUBSCRIPTION_STOREKEEPER_QUEUE_ADD,
      variables: { languageId, },
      updateQuery: (prev, { subscriptionData, }) => {
        if (!subscriptionData.data) return null;
        const newTransportation = subscriptionData.data.addTransportationToCentralAppQueue;
        return {
          ...prev,
          fetchCentralQueueTransportation: [
            ...prev.fetchCentralQueueTransportation,
            newTransportation,
          ],
        };
      },
    });

    this.unsubscribeUpdate = subscribeToMore({
      document: SUBSCRIPTION_STOREKEEPER_QUEUE_UPDATE,
      variables: { languageId, },
      updateQuery: (prev, { subscriptionData, }) => {
        if (!subscriptionData.data) return null;
        const updatedTransportation = subscriptionData.data.updateTransportationInCentralAppQueue;
        return {
          ...prev,
          fetchCentralQueueTransportation: prev.fetchCentralQueueTransportation.map((q) => {
            if (q.id === updatedTransportation.id) return updatedTransportation;
            return q;
          }),
        };
      },
    });

    this.unsubscribeRemove = subscribeToMore({
      document: SUBSCRIPTION_STOREKEEPER_QUEUE_REMOVE,
      updateQuery: (prev, { subscriptionData, }) => {
        if (!subscriptionData.data) return null;
        const removeId = subscriptionData.data.removeTransportationFromCentralAppQueue.id;
        return {
          ...prev,
          fetchCentralQueueTransportation: prev.fetchCentralQueueTransportation.filter(
            (q) => q.id !== removeId
          ),
        };
      },
    });
  }


  componentWillUnmount() {
    if (this.unsubscribeAdd) {
      this.unsubscribeAdd();
      this.unsubscribeAdd = null;
    }
    if (this.unsubscribeUpdate) {
      this.unsubscribeUpdate();
      this.unsubscribeUpdate = null;
    }
    if (this.unsubscribeRemove) {
      this.unsubscribeRemove();
      this.unsubscribeRemove = null;
    }
  }


  render() {
    const {
      // data
      queue,
      activeTab,
      platform,
      languageId,
      translations,
      translationsCommon,
      // functions
      onCall,
      onDetail,
    } = this.props;
    return (
      <>
        <Mutation mutation={MUTATION_CHANGE_TAB_STOREKEEPER_QUEUE}>
          {(mutationChangeTab) => (
            <WizardPanel>
              <WizardTab
                name={QUEUE_TABS.ALL}
                activeTab={activeTab}
                onClick={(newTab) => mutationChangeTab({ variables: { tab: newTab, }, })}
              >
                {translations.centralAppStorekeeper.tabAllPlatforms}
              </WizardTab>
              <WizardTab
                name={QUEUE_TABS.PLATFORM}
                activeTab={activeTab}
                onClick={(newTab) => mutationChangeTab({ variables: { tab: newTab, }, })}
              >
                {translations.centralAppStorekeeper.tabPlatform}
              </WizardTab>
            </WizardPanel>
          )}
        </Mutation>

        {(activeTab === QUEUE_TABS.ALL) && (
          <QueueAll
            // data
            queue={queue}
            translations={translations}
            translationsCommon={translationsCommon}
            // functions
            onCall={onCall}
            onDetail={onDetail}
          />
        )}

        {(activeTab === QUEUE_TABS.PLATFORM) && (
          <QueuePlatforms
            // data
            queue={queue}
            platform={platform}
            languageId={languageId}
            translations={translations}
            translationsCommon={translationsCommon}
            // functions
            onCall={onCall}
            onDetail={onDetail}
          />
        )}
      </>
    );
  }
}


QueueView.propTypes = {
  // data
  queue: arrayOf(object).isRequired,
  activeTab: string.isRequired,
  platform: object,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  // functions
  onCall: func.isRequired,
  onDetail: func.isRequired,
  subscribeToMore: func.isRequired,
};

QueueView.defaultProps = {
  platform: null,
};


export default QueueView;
