import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Customs = ({ ...props }) => (
  <StyledSvg viewBox="0 0 32 32" {...props}>
    <path d="M 18 4 L 18 5 L 18 6 L 16 6 L 16 8 L 18.15625 8 C 18.07324 8.3193351 18 8.6559037 18 9 C 18 11.197294 19.802706 13 22 13 C 24.197294 13 26 11.197294 26 9 C 26 8.6559037 25.92676 8.3193351 25.84375 8 L 26 8 L 26 7 L 26 5 L 26 4 L 25 4 L 19 4 L 18 4 z M 20.28125 8 L 23.71875 8 C 23.888204 8.2945672 24 8.6311212 24 9 C 24 10.116414 23.116414 11 22 11 C 20.883586 11 20 10.116414 20 9 C 20 8.6311212 20.111796 8.2945672 20.28125 8 z M 19.84375 14 C 18.330989 14 16.957806 14.865638 16.28125 16.21875 L 16.25 16.28125 L 16.21875 16.3125 L 15.28125 19 L 11 19 L 11 21 L 16 21 L 16.71875 21 L 16.9375 20.34375 L 18.09375 17.03125 L 18.125 17 C 18.480415 16.38085 19.123389 16 19.84375 16 L 24 16 C 24.233802 16 24.448872 16.053519 24.65625 16.125 L 19 23.28125 L 19 22 L 17 22 L 17 28 L 19 28 L 19 26.5 L 19.40625 26 L 26 26 L 26 28 L 28 28 L 28 18 C 28 15.802666 26.197334 14 24 14 L 19.84375 14 z M 4 15 L 4 23 L 4 24 L 4 26.03125 C 4 27.10706 4.89294 28 5.96875 28 L 13.03125 28 C 14.10706 28 15 27.10706 15 26.03125 L 15 24 L 15 23 L 14 23 L 6 23 L 6 15 L 4 15 z M 25.96875 17.65625 C 25.987785 17.768482 26 17.881765 26 18 L 26 24 L 20.96875 24 L 25.96875 17.65625 z M 6 25 L 13 25 L 13 26 L 6 26 L 6 25 z"/>
  </StyledSvg>
);

export default Customs;
