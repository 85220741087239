import { RESERVATION_TYPES, } from '../../../globals';
import { clearSecondsAndMilliseconds, clearTime, } from '../../../logic/date';


/**
 * Dropdown - Reservation Types
 */
export const getReservationTypesOptions = (translations, allOptions = true) => {
  const reservationTypes = [
    {
      id: RESERVATION_TYPES.DAY,
      name: translations.dropDownOptions.transportationReservationTypeDropDown.registration,
    },
    {
      id: RESERVATION_TYPES.INTERVAL,
      name: translations.dropDownOptions.transportationReservationTypeDropDown.reservationTimeWindow, // eslint-disable-line
    },
    {
      id: RESERVATION_TYPES.INTERVAL_PLATFORM,
      name: translations.dropDownOptions.transportationReservationTypeDropDown.reservationTimeWindowWithPlatform, // eslint-disable-line
    },
  ];

  if (allOptions) {
    return reservationTypes;
  }

  return [ reservationTypes[0], ];
};

export const getReservationTypesOptionsByTransporationType = (
  currentTransportationTypeId,
  transportationTypeForRS,
  translations,
  allOptions = true
) => {
  const reservationTypes = getReservationTypesOptions(translations, allOptions);
  if (currentTransportationTypeId === transportationTypeForRS) {
    return reservationTypes.filter((reservationType) => reservationType.id !== RESERVATION_TYPES.DAY);
  }
  return reservationTypes;
};

/**
 * Map values to mutation Create Transportation
 */
export const parseCreateTransportationMutationVariables = (reservationForms, detailValues) => {
  let fillDate = false;
  let filTimeFromTo = false;
  let fillPlatform = false;

  return {
    // Reservation
    reservations: reservationForms.map((item) => {
      const { values, } = item;
      if (values.resReservationType) {
        fillDate = values.resReservationType.id === RESERVATION_TYPES.DAY;
        filTimeFromTo = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
          || values.resReservationType.id === RESERVATION_TYPES.INTERVAL;
        fillPlatform = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM;
      }

      return ({
        no: values.resNo,
        transportationTypeId: values.resTransportationType
          ? values.resTransportationType.id
          : undefined,
        reservationTypeId: values.resReservationType
          ? values.resReservationType.id
          : undefined,
        platformId: fillPlatform && values.resPlatform
          ? values.resPlatform.id
          : undefined,
        date: fillDate ? values.resDate : undefined,
        timeFrom: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeFrom) : undefined,
        timeTo: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeTo) : undefined,
        carQuantity: values.carQuantity ? parseInt(values.carQuantity.id, 10) : undefined,
        carCompanyId: values.carCompany ? values.carCompany.id : undefined,
        destinationId: values.destination ? values.destination.id : undefined,
      });
    }),

    // Company
    company: {
      name: detailValues.compName,
      identificationNumber: detailValues.compIdentificationNumber,
      vatNumber: detailValues.compVatNumber,
      street: detailValues.compStreet,
      city: detailValues.compCity,
      zip: detailValues.compZip,
      state: detailValues.compState,
      contactPerson: detailValues.compContactPerson,
      email: detailValues.compEmail,
      phoneNumber: detailValues.compPhoneNumber,
    },

    // Driver
    driver: {
      name: detailValues.driverName,
      phoneNumber: detailValues.driverPhoneNumber,
      languageId: detailValues.languageId ? detailValues.languageId.id : undefined,
    },

    // Truck
    truck: {
      registrationNumber: detailValues.truckRegistrationNumber,
    },

    // trailer
    trailer: {
      registrationNumber: detailValues.trailerRegistrationNumber,
    },

    extra: {
      note: detailValues.note,
    },

    // other
    autoStartOnCombinedTransportation: detailValues.autoStartOnCombinedTransportation,
  };
};


/**
 * Map values to mutation Update Transportation
 */
export const parseTransportationMutationVariables = (values) => {
  let fillDate = false;
  let filTimeFromTo = false;
  let fillPlatform = false;

  if (values.resReservationType) {
    fillDate = values.resReservationType.id === RESERVATION_TYPES.DAY;
    filTimeFromTo = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
      || values.resReservationType.id === RESERVATION_TYPES.INTERVAL;
    fillPlatform = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM;
  }

  return {
    // Reservation
    reservation: {
      no: values.resNo,
      transportationTypeId: values.resTransportationType
        ? values.resTransportationType.id
        : undefined,
      reservationTypeId: values.resReservationType
        ? values.resReservationType.id
        : undefined,
      platformId: fillPlatform && values.resPlatform
        ? values.resPlatform.id
        : undefined,
      date: fillDate ? values.resDate : undefined,
      timeFrom: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeFrom) : undefined,
      timeTo: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeTo) : undefined,
      carQuantity: values.carQuantity ? parseInt(values.carQuantity.id, 10) : undefined,
      carCompanyId: values.carCompany ? values.carCompany.id : undefined,
      destinationId: values.destination ? values.destination.id : undefined,
    },

    // Company
    company: {
      name: values.compName,
      identificationNumber: values.compIdentificationNumber,
      vatNumber: values.compVatNumber,
      street: values.compStreet,
      city: values.compCity,
      zip: values.compZip,
      state: values.compState,
      contactPerson: values.compContactPerson,
      email: values.compEmail,
      phoneNumber: values.compPhoneNumber,
    },

    // Driver
    driver: {
      name: values.driverName,
      phoneNumber: values.driverPhoneNumber,
      languageId: values.languageId ? values.languageId.id : undefined,
    },

    // Truck
    truck: {
      registrationNumber: values.truckRegistrationNumber,
    },

    // trailer
    trailer: {
      registrationNumber: values.trailerRegistrationNumber,
    },

    extra: {
      note: values.note,
    },
  };
};


/**
 * Map values to mutation Detail Transportation
 */
export const parseTransportationDetailMutationVariables = (values) => {
  return {
    extra: {
      note: values.note,
    },
  };
};


/**
 * Reservation Types - Badge Color
 */
export const getReservationTypeColor = (reservationTypeId) => {
  const colors = {
    default: {
      color: undefined,
      background: undefined,
    },
    1: {
      color: 'white',
      background: '#e49b15',
    },
    2: {
      color: 'white',
      background: '#18a7a0',
    },
    3: {
      color: 'white',
      background: '#249bbf',
    },
  };

  if (Object.prototype.hasOwnProperty.call(colors, reservationTypeId)) {
    return colors[reservationTypeId];
  }
  return colors.default;
};

export const getReservationTimeToByCarQuantity = (carQuantity, unloadDurations, timeFrom) => {
  const unloadDuration = unloadDurations
    .find((duration) => duration.carQuantityFrom <= carQuantity
      && duration.carQuantityTo >= carQuantity);
  return new Date(timeFrom.getTime() + (unloadDuration.timeInMinutes * 60 * 1000));
};
