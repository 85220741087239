import React from 'react';
import { func, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_GATE_RECORD_DETAIL, } from '../../gql/queries';
import Modal from '../../../../atoms/Modal/Modal';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import Col from '../../../../atoms/Col/Col';
import FormBackground from '../../../../components/Form/FormBackground';
import Row from '../../../../atoms/Row/Row';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import ValueText from '../../../../atoms/Values/ValueText';
import { parseDateToHuman, } from '../../../../logic/date';
import StyledPictures from '../../../Transportation/components/TransportationDetail/styles/StyledPictures';


const { REACT_APP_REST, } = process.env;

const RecordView = ({
  // data
  recordId,
  languageId,
  translations,
  // methods
  onToggle,
}) => (
  <Modal
    isOpen
    title={translations.gatehouse.titleRecordEditModal}
    size="M"
    onClose={onToggle}
    disablePadding
  >
    <Query
      query={QUERY_GATE_RECORD_DETAIL}
      fetchPolicy="network-only"
      variables={{ id: recordId, languageId, }}
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <div className="app--padding">
              <PartLoading />
            </div>
          );
        }
        if (error || !data.fetchOneGatehouseRecord) {
          return (
            <div className="app--padding">
              <PartError error={error} />
            </div>
          );
        }

        const form = data.fetchOneGatehouseRecord.form_in || data.fetchOneGatehouseRecord.form_out;
        const note = form ? form.note : null;

        return (
          <FormBackground>
            <Row>
              <Col MD={24}>
                <fieldset>
                  <legend>{translations.gatehouse.titleBasicForm}</legend>
                  <FormRowReadOnly label={translations.form.vehicleGroupName}>
                    <ValueText>{data.fetchOneGatehouseRecord.vehicleGroupName ? data.fetchOneGatehouseRecord.vehicleGroupName : translations.common.unknown}</ValueText>
                  </FormRowReadOnly>

                  <FormRowReadOnly label={translations.form.registrationNumber}>
                    <ValueText>{data.fetchOneGatehouseRecord.registrationNumber}</ValueText>
                  </FormRowReadOnly>

                  <FormRowReadOnly label={translations.form.time}>
                    <ValueText>{parseDateToHuman(new Date(data.fetchOneGatehouseRecord.time))}</ValueText>
                  </FormRowReadOnly>

                  <FormRowReadOnly label={translations.form.gateType}>
                    <ValueText>{data.fetchOneGatehouseRecord.gateType === 'in' ? translations.form.gateTypeEntrance : translations.form.gateTypeExit}</ValueText>
                  </FormRowReadOnly>

                  <FormRowReadOnly label={translations.form.driver}>
                    <ValueText>{data.fetchOneGatehouseRecord.driverName}</ValueText>
                  </FormRowReadOnly>

                  <FormRowReadOnly label={translations.form.phoneNumber}>
                    <ValueText>{data.fetchOneGatehouseRecord.driverPhoneNumber}</ValueText>
                  </FormRowReadOnly>

                  <FormRowReadOnly label={translations.form.note}>
                    <ValueText>{note || '-'}</ValueText>
                  </FormRowReadOnly>

                  <StyledPictures>
                    <FormRowReadOnly label="Foto">
                      {data.fetchOneGatehouseRecord.pictures.length > 0 && (
                      <ul>
                        { data.fetchOneGatehouseRecord.pictures.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index}>
                            <a href={`${REACT_APP_REST}/images/${item}`} target="_blank" rel="noopener noreferrer">
                              <img
                                src={`${REACT_APP_REST}/images/${item}`}
                                alt={index}
                                width="100"
                                height="100"
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                      )}
                      {data.fetchOneGatehouseRecord.pictures.length === 0 && (<ValueText>-</ValueText>)}
                    </FormRowReadOnly>
                  </StyledPictures>

                </fieldset>
              </Col>
            </Row>
          </FormBackground>
        );
      }}
    </Query>
  </Modal>
);


RecordView.propTypes = {
  // data
  recordId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default RecordView;
