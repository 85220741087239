import React from 'react';
import {
  arrayOf, func, object,
} from 'prop-types';

import Item from './Item';
import StyledQueueList from './styles/StyledQueueList';

import PartInfo from '../../../../components/Parts/PartInfo';


const QueueAll = ({
  translations,
  translationsCommon,
  queue,
  onCall,
  onDetail,
}) => {
  if (queue.length < 1) {
    return (
      <PartInfo>
        <span className="color--watermark">
          {translations.centralAppStorekeeper.infoQueueNoTransportation}
        </span>
      </PartInfo>
    );
  }

  return (
    <StyledQueueList>
      {queue.map((item) => (
        <Item
          key={item.id}
          item={item}
          translations={translations}
          translationsCommon={translationsCommon}
          onCall={onCall}
          onDetail={onDetail}
        />
      ))}
    </StyledQueueList>
  );
};


QueueAll.propTypes = {
  // data
  queue: arrayOf(object).isRequired,
  translationsCommon: object.isRequired,
  translations: object.isRequired,
  // functions
  onCall: func.isRequired,
  onDetail: func.isRequired,
};


export default QueueAll;
