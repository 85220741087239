import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';

import Modal from '../../atoms/Modal/Modal';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Button from '../../atoms/Button/Button';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { TRANSLATIONS_YES_NO_MODAL, } from './translations';


const YesNoModal = ({
  // data
  isOpen,
  title,
  text,
  translations,
  yesButtonTranslation,
  noButtonTranslation,
  // methods
  onChoose,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={() => onChoose(false)}
    title={title}
    size="SM"
  >
    <Row formGroup>
      <Col>
        {text}
      </Col>
    </Row>
    <Row>
      <Col XS={12} SM={12}>
        <Button
          onClick={() => onChoose(false)}
          shape="outline"
        >
          {noButtonTranslation && (noButtonTranslation)}
          {!noButtonTranslation && (translations.common.no)}
        </Button>
      </Col>

      <Col XS={12} SM={12} textAlign="right">
        <Button
          onClick={() => onChoose(true)}
          shape="outline"
          color="success"
        >
          {yesButtonTranslation && (yesButtonTranslation)}
          {!yesButtonTranslation && (translations.common.yes)}
        </Button>
      </Col>
    </Row>
  </Modal>
);


YesNoModal.propTypes = {
  // data
  isOpen: bool.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  translations: object.isRequired,
  yesButtonTranslation: string,
  noButtonTranslation: string,
  // methods
  onChoose: func.isRequired,
};

YesNoModal.defaultProps = {
  yesButtonTranslation: null,
  noButtonTranslation: null,
};

export default withTranslations(TRANSLATIONS_YES_NO_MODAL)(YesNoModal);
