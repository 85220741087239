import React from 'react';
import {
  func, shape, string, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_LIVE_MONITORING_TRANSPORTATIONS, } from '../../gql/queries';
import { SUBSCRIPTION_LIVE_MONITORING_TRANSPORTATIONS_ADD, SUBSCRIPTION_LIVE_MONITORING_TRANSPORTATIONS_REMOVE, } from '../../gql/subscriptions';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import MonitoringColumnList from './MonitoringColumnList';


const MonitoringColumn = ({
  // data
  columnData,
  columnData: {
    state,
    title,
  },
  languageId,
  translations,
  translationsCommon,
  // methods
  onDetail,
}) => (
  <li className="monitoring--column">

    <h4 className="monitoring--col-title">
      {translations.monitoring[title]}
    </h4>

    <Query
      query={QUERY_LIVE_MONITORING_TRANSPORTATIONS}
      variables={{
        state,
        languageId,
      }}
      fetchPolicy="network-only"
    >
      {({
        loading, error, data, subscribeToMore,
      }) => {
        if (loading) return <PartLoading />;
        if (error || !data.fetchLiveTransportation) return <PartError error={error} />;

        return (
          <MonitoringColumnList
            // data
            transportations={data.fetchLiveTransportation}
            translations={translations}
            translationsCommon={translationsCommon}
            columnData={columnData}
            onDetail={onDetail}
            // methods
            subscribeToAddLiveMonitoring={() => subscribeToMore({
              document: SUBSCRIPTION_LIVE_MONITORING_TRANSPORTATIONS_ADD,
              variables: {
                state,
                languageId,
              },
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return null;
                const newTransportation = subscriptionData.data.addLiveTransportation;
                const oldTransportations = prev.fetchLiveTransportation.filter(
                      (item) => item.id !== newTransportation.id
                );

                let transportations = [
                  newTransportation,
                  ...oldTransportations,
                ];
                if (state === 'plan' || state === 'waiting' || state === 'clearing') {
                  transportations = [
                    ...oldTransportations,
                    newTransportation,
                  ];
                }
                return {
                  fetchLiveTransportation: transportations,
                };
              },
            })}
            subscribeToRemoveLiveMonitoring={() => subscribeToMore({
              document: SUBSCRIPTION_LIVE_MONITORING_TRANSPORTATIONS_REMOVE,
              variables: {
                state,
              },
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return null;
                const removeId = subscriptionData.data.removeLiveTransportation;
                return {
                  fetchLiveTransportation: prev.fetchLiveTransportation.filter(
                    (item) => item.id !== removeId
                  ),
                };
              },
            })}
          />
        );
      }}
    </Query>

  </li>
);


MonitoringColumn.propTypes = {
  columnData: shape({
    state: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  onDetail: func.isRequired,
};


export default MonitoringColumn;
