import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';

import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
} from '../../../../../logic/form/common';
import { moveFormStructure, } from '../forms';
import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalMoveView from './ModalMoveView';


class ModalMove extends Component {
  state = {
    moveForm: moveFormStructure,
  }


  handleChangeMoveForm = (name, value) => {
    const { moveForm, } = this.state;

    this.setState({
      moveForm: changeAndValidateInput(moveForm, name, value),
    });
  }


  handleMove = (mutationMove) => {
    const {
      moveForm,
      moveForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const newDetailForm = validateAndMergeWholeForm(moveForm);

    if (!newDetailForm.isValid) {
      this.setState({
        moveForm: newDetailForm,
      });
    } else {
      mutationMove({
        variables: {
          sourcePlatformId: platformId,
          targetPlatformId: values.platform === null
            ? undefined
            : values.platform.id,
          transportationId,
        },
      });
    }
  }

  handleMoveCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleMoveSuccess,
    });
  }

  handleMoveError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleMoveError,
    });
  }


  render() {
    const { moveForm, } = this.state;
    const {
      // data
      platformId,
      transportationId,
      languageId,
      translations,
      // methods
      onToggle,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.titleModalMove}
        disablePadding
      >
        <ModalMoveView
          // data
          platformId={platformId}
          moveForm={moveForm}
          transportationId={transportationId}
          languageId={languageId}
          translations={translations}
          // methods
          onToggle={onToggle}
          onChangeMoveForm={this.handleChangeMoveForm}
          onMove={this.handleMove}
          onMoveCompleted={this.handleMoveCompleted}
          onMoveError={this.handleMoveError}
        />
      </Modal>
    );
  }
}


ModalMove.propTypes = {
  // data
  platformId: string.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(ModalMove);
