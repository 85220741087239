import React, { Component, } from 'react';
import {
  func, shape, bool, object,
} from 'prop-types';

import { QUERY_DRIVER_DETAIL_TRANSLATIONS, } from '../../gql/translations';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initDriverForm, } from '../forms/structure';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import DriverCreateView from './DriverCreateView';


class DriverCreate extends Component {
  state = {
    ...initDriverForm,
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * onCreate
   */
  handleCreate = (mutate) => {
    const { detailForm, detailForm: { values, }, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const variables = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        info: values.info,
        trucks: values.trucks.map((truck) => truck.id),
      };
      mutate({
        variables,
      });
    }
  }


  /**
   * onCreate - Completed
   */
  handleCreateComplete = (responseData) => {
    const {
      translations, onToggle, onCreated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });

    if (onCreated) onCreated(responseData);
  }


  /**
   * onCreate - Error
   */
  handleCreateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const { translations, modalData: { isOpen, }, onToggle, } = this.props;

    if (!isOpen) return null;

    return (
      <Modal
        title={translations.directory.createModalDriverTitle}
        isOpen
        onClose={onToggle}
        size="SM"
        disablePadding
      >
        <DriverCreateView
          detailForm={detailForm}
          translations={translations}
          onChange={this.handleValueChange}
          onToggle={onToggle}
          onCreate={this.handleCreate}
          onCreateComplete={this.handleCreateComplete}
          onCreateError={this.handleCreateError}
        />
      </Modal>
    );
  }
}


DriverCreate.propTypes = {
  translations: object.isRequired,
  modalData: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
  onCreated: func,
  addNotification: func.isRequired,
};

DriverCreate.defaultProps = {
  onCreated: undefined,
};


export default pipe(
  withNotifications,
  withTranslations(QUERY_DRIVER_DETAIL_TRANSLATIONS),
)(DriverCreate);
