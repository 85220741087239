import React, { Fragment, } from 'react';
import {
  string, object, func, shape, arrayOf, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DD_VEHICLE_GROUPS, } from '../../../Directory/gql/queries';
import { parseDateToHuman, } from '../../../../logic/date';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import Table from '../../../../atoms/Table/Table';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';
import Search from '../../../../styles/icons/Search';
import Bin from '../../../../styles/icons/Bin';
import Button from '../../../../atoms/Button/Button';


const COL_COUNT = 8;


const Header = ({
  // data
  filter,
  translations,
  languageId,
  selectedItems,
  allItemsSelected,
  // methods
  onChangeSort,
  onChangeParam,
  onToggleAllItems,
  onDelete,
}) => (
  <thead>
    <tr>
      <th />

      <THSortable
        title={translations.form.time}
        name="time"
        isActiveFilter={!!filter.params.time}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.gateType}
        name="gateType"
        isActiveFilter={!!filter.params.gateType}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.registrationNumber}
        name="registrationNumber"
        isActiveFilter={!!filter.params.registrationNumber}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.vehicleGroupName}
        name="vehicleGroupId"
        isActiveFilter={filter.params.vehicleGroupId !== null}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.driver}
        name="driverName"
        isActiveFilter={filter.params.driverName}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.phoneNumber}
        name="driverPhoneNumber"
        isActiveFilter={filter.params.driverPhoneNumber}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--header-title table--text-align-right"
        style={{ minWidth: '10rem', width: '10rem', }}
      >
        {translations.transportation.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <input type="checkbox" checked={allItemsSelected} onChange={(e) => onToggleAllItems(e.target.checked)} style={{ marginLeft: '12px'}} />
      </th>

      <th>
        <InputDateSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.timeFrom}
          active={!!filter.params.timeFrom}
          clearable
          onChange={(newDate) => onChangeParam('timeFrom', newDate, false)}
        />
      </th>

      <th>
        <InputSelect
          value={filter.params.gateType}
          active={!!filter.params.gateType}
          options={[ { id: 'in', name: translations.form.gateTypeEntrance, }, { id: 'out', name: translations.form.gateTypeExit, }, ]}
          onChange={(option) => onChangeParam('gateType', option, false)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable
          placeholder={translations.common.filter}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.registrationNumber}
          active={filter.params.registrationNumber !== ''}
          onChange={(e) => onChangeParam('registrationNumber', e.target.value)}
          onClear={() => onChangeParam('registrationNumber', '')}
          size="sm"
        />
      </th>

      <th>
        <Query
          query={QUERY_DD_VEHICLE_GROUPS}
          variables={{ languageId, }}
        >
          {({ data, loading, error, }) => (
            <InputSelect
              value={filter.params.vehicleGroupId}
              active={!!filter.params.vehicleGroupId}
              options={
                (data && data.fetchAllVehicleGroups)
                  ? [ { id: 0, name: translations.common.unknown, }, ...data.fetchAllVehicleGroups, ]
                  : []
              }
              onChange={(option) => onChangeParam('vehicleGroupId', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={loading}
              error={error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.driverName}
          active={filter.params.driverName !== ''}
          onChange={(e) => onChangeParam('driverName', e.target.value)}
          onClear={() => onChangeParam('driverName', '')}
          size="sm"
        />
      </th>
      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.driverPhoneNumber}
          active={filter.params.driverPhoneNumber !== ''}
          onChange={(e) => onChangeParam('driverPhoneNumber', e.target.value)}
          onClear={() => onChangeParam('driverPhoneNumber', '')}
          size="sm"
        />
      </th>
      <th
        className="table--header-title table--text-align-right"
      >
        <Button
          size="xs"
          color="error"
          onClick={() => onDelete(null)}
          disabled={selectedItems.length === 0}
        >
          {translations.common.deleteSelected}
        </Button>
      </th>
    </tr>

    <tr>
      <th />
      <th>
        <InputDateSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.timeTo}
          active={!!filter.params.timeTo}
          clearable
          onChange={(newDate) => onChangeParam('timeTo', newDate, false)}
        />
      </th>

      <th />

      <th />

      <th />

      <th />
      <th />
      <th />
    </tr>
  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  selectedItems: arrayOf(string).isRequired,
  allItemsSelected: bool.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
  onDelete: func.isRequired,
  onToggleAllItems: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  selectedItems,
  // methods
  onDetail,
  onDelete,
  onSelectItem,
}) => {
  if (!data.filterGatehouseRecords && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={
          data && data.filterGatehouseRecords && data.filterGatehouseRecords.rows.length
        }
      />
    );
  }
  if (error || !data.filterGatehouseRecords) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterGatehouseRecords.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.gatehouse.noRecords}
      />
    );
  }

  return (
    <tbody>
      {data.filterGatehouseRecords.rows.map((item) => (
        <tr key={item.id}>
          <td>
            <input type="checkbox" checked={selectedItems.indexOf(item.id) !== -1} onChange={(e) => onSelectItem(item.id, e.target.checked)} />
          </td>
          <td>
            {parseDateToHuman(new Date(item.time))}
          </td>
          <td>{item.gateType === 'in' ? translations.form.gateTypeEntrance : translations.form.gateTypeExit}</td>
          <td>
            {item.registrationNumber}
          </td>
          <td>
            {item.vehicleGroupName ? item.vehicleGroupName : translations.common.unknown}
          </td>
          <td>
            {item.driverName}
          </td>
          <td>
            {item.driverPhoneNumber}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => onDetail(item.id)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    onClick={() => onDelete([ item.id, ])}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterGateRecords: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        time: string,
        registrationNumber: string.isRequired,
        vehicleGroupName: string.isRequired,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  selectedItems: arrayOf(string).isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
  onSelectItem: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const TableView = ({
  // data
  filter,
  queryData,
  translations,
  languageId,
  selectedItems,
  allItemsSelected,
  // methods
  onDetail,
  onDelete,
  onSelectItem,
  onToggleAllItems,
  onChangeSort,
  onChangeParam,
}) => (
  <Fragment>
    <Table fillContent>
      <Header
        // data
        filter={filter}
        translations={translations}
        languageId={languageId}
        selectedItems={selectedItems}
        allItemsSelected={allItemsSelected}
        // methods
        onChangeSort={onChangeSort}
        onChangeParam={onChangeParam}
        onDelete={onDelete}
        onToggleAllItems={onToggleAllItems}
      />
      <Rows
        // data
        {...queryData}
        translations={translations}
        selectedItems={selectedItems}
        // methods
        onDetail={onDetail}
        onDelete={onDelete}
        onSelectItem={onSelectItem}
      />
    </Table>
  </Fragment>
);


TableView.propTypes = {
  filter: shape({
    params: object.isRequired,
  }).isRequired,
  queryData: shape({
    loading: bool.isRequired,
    error: object,
    data: object,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  selectedItems: arrayOf(string).isRequired,
  allItemsSelected: bool.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
  onSelectItem: func.isRequired,
  onToggleAllItems: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


export default TableView;
