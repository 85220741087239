import React, { Component, } from 'react';
import {
  string, shape, object, func, bool,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import {
  MUTATION_CLEAR_TRANSPORTATION,
  MUTATION_REPEAT_CALL_TRANSPORTATION,
  MUTATION_CANCEL_TRANSPORTATION,
  MUTATION_CONTINUE_TRANSPORTATION,
  MUTATION_FINISH_TRANSPORTATION,
} from '../../../gql/mutations';
import { parseTimeToHuman, } from '../../../../../logic/date';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import StyledTransportation from '../styles/StyledTransportation';
import Search from '../../../../../styles/icons/Search';
import ButtonIcon from '../../../../../atoms/Button/ButtonIcon';
import Customs from '../../../../../styles/icons/Customs';
import CircleWithCross from '../../../../../styles/icons/CircleWithCross';


const TRANSPORTATION_STATES = {
  CALLED: 'called',
  CLEARING: 'clearing',
  INTERRUPTED: 'interrupted',
  SOLVING: 'solving',
};


class TransportationView extends Component {
  renderButtons = () => {
    const { transportation: { state, }, } = this.props;

    const types = {
      [TRANSPORTATION_STATES.CALLED]: this.renderCalledBtn,
      [TRANSPORTATION_STATES.CLEARING]: this.renderClearingBtn,
      [TRANSPORTATION_STATES.INTERRUPTED]: this.renderInterruptedBtn,
      [TRANSPORTATION_STATES.SOLVING]: this.renderSolvingBtn,
    };

    if (Object.prototype.hasOwnProperty.call(types, state)) return types[state]();
    return null;
  }


  renderCalledBtn = () => {
    const {
      repeatCall,
      translations,
      // Clear
      onClearTransportation,
      onClearTransportationCompleted,
      onClearTransportationError,
      // Repeat Call
      onRepeatCallTransportation,
      onRepeatCallTransportationCompleted,
      onRepeatCallTransportationError,
      // Cancel
      onCancelTransportation,
      onCancelTransportationCompleted,
      onCancelTransportationError,
    } = this.props;

    return (
      <>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          <Mutation
            mutation={MUTATION_REPEAT_CALL_TRANSPORTATION}
            onCompleted={onRepeatCallTransportationCompleted}
            onError={onRepeatCallTransportationError}
          >
            {(mutationRepeatCall, { loading, }) => (
              <ButtonLoader
                size="sm"
                disabled={repeatCall.disabled}
                onClick={() => onRepeatCallTransportation(mutationRepeatCall)}
                isLoading={loading}
                color="success"
                transparent
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnTransportationRepeatCall}
              </ButtonLoader>
            )}
          </Mutation>
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          <Mutation
            mutation={MUTATION_CLEAR_TRANSPORTATION}
            onCompleted={onClearTransportationCompleted}
            onError={onClearTransportationError}
          >
            {(mutationClear, { loading, }) => (
              <ButtonLoader
                size="sm"
                color="success"
                onClick={() => onClearTransportation(mutationClear)}
                isLoading={loading}
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnTransportationClear}
              </ButtonLoader>
            )}
          </Mutation>
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom" />
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom">
          <Mutation
            mutation={MUTATION_CANCEL_TRANSPORTATION}
            onCompleted={onCancelTransportationCompleted}
            onError={onCancelTransportationError}
          >
            {(mutationCancel, { loading, }) => (
              <ButtonLoader
                size="sm"
                onClick={() => onCancelTransportation(mutationCancel)}
                isLoading={loading}
                color="error"
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnTransportationCancel}
              </ButtonLoader>
            )}
          </Mutation>
        </div>
      </>
    );
  }


  renderClearingBtn = () => {
    const {
      transportation,
      translations,
      resources,
      // interrupt
      onInterrupt,
      // forward
      onForward,
      // detail
      onDetail,
      // finish
      onFinish,
      onMove,
    } = this.props;

    return (
      <>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          {resources.storekeeper_forward_action && (
            <Button
              size="sm"
              onClick={onForward}
              transparent
              paddingHalf
            >
              {translations.centralAppStorekeeper.btnTransportationForward}
            </Button>
          )}
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          {resources.storekeeper_move_action && (
            <Button
              size="sm"
              onClick={onMove}
              transparent
              paddingHalf
            >
              {translations.centralAppStorekeeper.btnTransportationMove}
            </Button>
          )}
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom">
          {resources.storekeeper_delay_action && (
            <Button
              size="sm"
              onClick={onInterrupt}
              transparent
              color="error"
              paddingHalf
            >
              {translations.centralAppStorekeeper.btnTransportationDelay}
            </Button>
          )}
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom">
          <Button
            size="sm"
            color="error"
            onClick={onFinish}
            paddingHalf
          >
            {translations.centralAppStorekeeper.btnTransportationFinish}
          </Button>
        </div>
      </>
    );
  }


  renderInterruptedBtn = () => {
    const {
      transportation,
      translations,
      // detail,
      onDetail,
      // finish
      onFinish,
      // continue
      onContinueTransportation,
      onContinueTransportationCompleted,
      onContinueTransportationError,
    } = this.props;

    return (
      <>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          <Mutation
            mutation={MUTATION_CONTINUE_TRANSPORTATION}
            onCompleted={onContinueTransportationCompleted}
            onError={onContinueTransportationError}
          >
            {(mutationContinue, { loading, }) => (
              <ButtonLoader
                size="sm"
                onClick={() => onContinueTransportation(mutationContinue)}
                isLoading={loading}
                color="success"
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnTransportationContinue}
              </ButtonLoader>
            )}
          </Mutation>
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          <Button
            size="sm"
            onClick={onDetail}
            color="tertiary"
            disabled={!transportation.genericFormId}
            paddingHalf
          >
            {translations.centralAppStorekeeper.btnTransportationForm}
          </Button>
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom" />
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom">
          <Button
            size="sm"
            onClick={onFinish}
            color="error"
            paddingHalf
          >
            {translations.centralAppStorekeeper.btnTransportationFinish}
          </Button>
        </div>
      </>
    );
  }


  renderSolvingBtn = () => {
    const {
      transportation,
      translations,
      // detail,
      onDetail,
      // finish
      onFinishTransportation,
      onFinishTransportationCompleted,
      onFinishTransportationError,
    } = this.props;

    return (
      <>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top" />
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-top">
          <Button
            size="sm"
            onClick={onDetail}
            color="tertiary"
            disabled={!transportation.genericFormId}
            paddingHalf
          >
            {translations.centralAppStorekeeper.btnTransportationForm}
          </Button>
        </div>
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom" />
        <div className="storekeeperTransportation--main-actions-btn storekeeperTransportation--main-actions-btn-bottom">
          <Mutation
            mutation={MUTATION_FINISH_TRANSPORTATION}
            onCompleted={onFinishTransportationCompleted}
            onError={onFinishTransportationError}
          >
            {(mutationFinish, { loading, }) => (
              <ButtonLoader
                size="sm"
                color="error"
                onClick={() => onFinishTransportation(mutationFinish)}
                isLoading={loading}
                paddingHalf
              >
                {translations.centralAppStorekeeper.btnTransportationFinish}
              </ButtonLoader>
            )}
          </Mutation>
        </div>
      </>
    );
  }


  render() {
    const {
      transportation: {
        state,
        transportationTypeName,
        truckRegistration,
        startTime,
        driverName,
        note,
        customs,
        notArrivedInLimit,
      },
      translations,
      translationsCommon,
      onTransportationDetail,
    } = this.props;

    return (
      <StyledTransportation state={state} note={note}>

        <div className="storekeeperTransportation--panel">
          <div className="storekeeperTransportation--panel-state">
            {translationsCommon.storekeeperClearingStates[state]}
          </div>
          <div>
            <ButtonIcon
              size="lg"
              transparent
              onClick={() => onTransportationDetail()}
              style={{ width: 'auto', marginLeft: '0.5em', color: '#eee', }}
            >
              <Search />
            </ButtonIcon>
          </div>
          <div className="storekeeperTransportation--panel-time">
            {startTime ? `${translations.centralAppStorekeeper.timeStarted}: ${parseTimeToHuman(new Date(startTime))}` : ''}
          </div>
        </div>

        <div className="storekeeperTransportation--main">
          <div className="storekeeperTransportation--main-info">
            <div className="storekeeperTransportation--main-info-no">
              {driverName}
              {notArrivedInLimit && (
                <CircleWithCross style={{ height: 'auto', marginLeft: '4px', }} />
              )}
            </div>
            <div className="storekeeperTransportation--main-info-truck">
              {truckRegistration}
            </div>
            <div className="storekeeperTransportation--main-info-transportationType">
              {transportationTypeName}
              {customs && (
                <Customs style={{ width: '20px', height: 'auto', marginLeft: '2px', }} />
              )}
            </div>
          </div>
          <div className="storekeeperTransportation--main-actions">
            {this.renderButtons()}
          </div>
        </div>

        {note && (
          <div className="storekeeperTransportation--note">
            {note}
          </div>
        )}

      </StyledTransportation>
    );
  }
}


TransportationView.propTypes = {
  transportation: shape({
    state: string.isRequired,
    transportationTypeName: string.isRequired,
    truckRegistration: string.isRequired,
    startTime: string,
    no: string.isRequired,
  }).isRequired,
  repeatCall: shape({
    disabled: bool.isRequired,
  }).isRequired,
  resources: object.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  onInterrupt: func.isRequired,
  onForward: func.isRequired,
  onMove: func.isRequired,
  onDetail: func.isRequired,
  onFinish: func.isRequired,
  onFinishTransportation: func.isRequired,
  onFinishTransportationCompleted: func.isRequired,
  onFinishTransportationError: func.isRequired,
  onContinueTransportation: func.isRequired,
  onContinueTransportationCompleted: func.isRequired,
  onContinueTransportationError: func.isRequired,
  onClearTransportation: func.isRequired,
  onClearTransportationCompleted: func.isRequired,
  onClearTransportationError: func.isRequired,
  onRepeatCallTransportation: func.isRequired,
  onRepeatCallTransportationCompleted: func.isRequired,
  onRepeatCallTransportationError: func.isRequired,
  onCancelTransportation: func.isRequired,
  onCancelTransportationCompleted: func.isRequired,
  onCancelTransportationError: func.isRequired,
  onTransportationDetail: func.isRequired,
};


export default TransportationView;
