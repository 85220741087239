import React, { Component, } from 'react';
import { Query, } from 'react-apollo';
import { func, } from 'prop-types';

import '../components/styles/Reporting.css';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageView from '../../../atoms/PageView/PageView';
import { QUERY_REPORT_NOTIFICATION_GROUP_SETTINGS, } from '../gql/queries';
import { client, } from '../../../logic/graphql/apollo';
import { withNotifications, } from '../../../logic/notifications/withNotifications';
import {
  MUTATION_CREATE_REPORT_NOTIFICATION_GROUP,
  MUTATION_DELETE_REPORT_NOTIFICATION_GROUP,
  MUTATION_UPDATE_REPORT_NOTIFICATION_GROUP,
} from '../gql/mutations';
import ReportNotificationGroupsSettings from '../components/ReportNotificationGroupsSettings';
import ReportNotificationGroupCreateModal from '../components/ReportNotificationGroupCreateModal';
import ModalDelete from '../../../components/modal/ModalDelete';


class ReportingNotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReportNotificationGroup: null,
      createModal: {
        isOpen: false,
      },
      deleteModal: {
        isOpen: false,
        id: null,
      },
    };
  }

  addNotification = (status, title, text = '') => {
    const { addNotification, } = this.props;
    addNotification({ status, title, text, });
  }

  handleSelectReportNotificationGroup = (reportNotificationGroupId, allReportNotificationGroups) => {
    this.setState(
      { selectedReportNotificationGroup: allReportNotificationGroups.find((r) => r.id === reportNotificationGroupId), }
    );
  }

  handleToggleCreateModal = () => {
    this.setState((prevState) => ({
      createModal: {
        isOpen: !prevState.createModal.isOpen,
      },
    }));
  }

  handleCreate = (name, reportId, refetch) => {
    if (!name) {
      this.addNotification('error', 'Zadejte název');
      return;
    }

    client.mutate({
      mutation: MUTATION_CREATE_REPORT_NOTIFICATION_GROUP,
      variables: {
        reportNotificationGroup: {
          name,
          reportId: parseInt(reportId, 10),
          userEmails: [],
          transportationTypeIds: [],
          compareInterval: 'week',
          sendInterval: 'week',
          sendIntervalDay: 1,
          sendIntervalHour: 9,
          parameters: [],
        },
      },
    })
      .then((result) => {
        this.addNotification('success', 'Uloženo');
        this.handleToggleCreateModal();
        refetch();
      })
      .catch((err) => {
        this.addNotification('error', 'Chyba', err.message);
      });
  }

  handleUpdate = (id, reportNotificationGroup, refetch) => {
    client.mutate({
      mutation: MUTATION_UPDATE_REPORT_NOTIFICATION_GROUP,
      variables: { id, reportNotificationGroup, },
    })
      .then((result) => {
        this.addNotification('success', 'Uloženo');
        refetch();
        this.setState({ selectedReportNotificationGroup: null, });
      })
      .catch((err) => {
        this.addNotification('error', 'Chyba', err.message);
      });
  }

  handleToggleDeleteModal = (id) => {
    this.setState((prevState) => ({
      deleteModal: {
        id,
        isOpen: !prevState.deleteModal.isOpen,
      },
    }));
  }

  handleDeleteCompleted = (refetch) => {
    refetch();
    this.setState({ selectedReportNotificationGroup: null, });
    this.addNotification('success', 'Smazáno');
  }

  render() {
    const { createModal, deleteModal, selectedReportNotificationGroup, } = this.state;

    return (
      <PageView>

        <Breadcrumb>
          <BreadcrumbItem to="/">Domů</BreadcrumbItem>
          <BreadcrumbItem>Notifikace</BreadcrumbItem>
        </Breadcrumb>

        <Query query={QUERY_REPORT_NOTIFICATION_GROUP_SETTINGS}>
          {({
            data, loading, refetch, error,
          }) => {
            if (loading) {
              return <></>;
            }

            return (
              <>
                {createModal.isOpen && (
                  <ReportNotificationGroupCreateModal
                    reports={data.getAllReports}
                    onClose={this.handleToggleCreateModal}
                    onCreate={(...args) => this.handleCreate(...args, refetch)}
                  />
                )}

                {deleteModal.isOpen && (
                  <ModalDelete
                    // data
                    title="Smazat"
                    text="Opravdu smazat?"
                    mutation={MUTATION_DELETE_REPORT_NOTIFICATION_GROUP}
                    variables={{ reportNotificationGroupId: deleteModal.id, }}
                    // methods
                    onToggle={this.handleToggleDeleteModal}
                    onCompleted={() => this.handleDeleteCompleted(refetch)}
                  />
                )}

                <ReportNotificationGroupsSettings
                  reportNotificationGroups={data.getReportNotificationGroups}
                  reports={data.getAllReports}
                  users={data.fetchUsersForReporting}
                  transportationTypes={data.fetchAllTransportationTypes}
                  selectedReportNotificationGroup={selectedReportNotificationGroup}
                  onCreate={this.handleToggleCreateModal}
                  onUpdate={(...args) => this.handleUpdate(...args, refetch)}
                  onDelete={this.handleToggleDeleteModal}
                  onSelect={(...args) => this.handleSelectReportNotificationGroup(...args, data.getReportNotificationGroups)}
                />
              </>
            );
          }}
        </Query>

      </PageView>
    );
  }
}

ReportingNotificationPage.propTypes = {
  addNotification: func.isRequired,
};


export default withNotifications(ReportingNotificationPage);
