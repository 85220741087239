import React from 'react';
import {
  shape, string, func, object, arrayOf,
} from 'prop-types';

import { RESERVATION_TYPES, } from '../../../../globals';
import { parseDateToHuman, } from '../../../../logic/date';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import ValueText from '../../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';


const EditReservationForm = ({
  form,
  values: {
    resNo,
    resTransportationType,
    resReservationType,
    resPlatform,
    resDate,
    resTimeFrom,
    resTimeTo,
    carQuantity,
    carCompany,
    destination,
  },
  translations,
  onChange,
}) => (
  <>

    <FormRow
      label={translations.form.reservationNumber}
      input={form.resNo}
    >
      <Input
        type="text"
        value={resNo}
        onChange={(e) => onChange(form.resNo.name, e.target.value)}
        status={form.resNo.status}
      />
    </FormRow>

    <FormRowReadOnly label={translations.form.transportationType}>
      <ValueText>
        {resTransportationType ? resTransportationType.name : '-'}
      </ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.reservationType}>
      <ValueText>
        {resReservationType ? resReservationType.name : '-'}
      </ValueText>
    </FormRowReadOnly>

    {(resReservationType && resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM) && (
      <FormRowReadOnly label={translations.form.platform}>
        <ValueText>
          {resPlatform ? resPlatform.name : '-'}
        </ValueText>
      </FormRowReadOnly>
    )}

    {(resReservationType && resReservationType.id === RESERVATION_TYPES.DAY) && (
      <FormRowReadOnly label={translations.form.date}>
        <ValueText>
          {resDate ? parseDateToHuman(new Date(resDate), false) : '-'}
        </ValueText>
      </FormRowReadOnly>
    )}

    {(resReservationType && resReservationType.id !== RESERVATION_TYPES.DAY) && (
      <>
        <FormRowReadOnly label={translations.form.timeWindow}>
          <ValueText>
            {`${resTimeFrom ? parseDateToHuman(new Date(resTimeFrom)) : '-'} - ${resTimeTo ? parseDateToHuman(new Date(resTimeTo)) : '-'}`}
          </ValueText>
        </FormRowReadOnly>
      </>
    )}

    {carQuantity && (
      <FormRowReadOnly label={translations.form.carQuantity}>
        <ValueText>
          {carQuantity.name}
        </ValueText>
      </FormRowReadOnly>
    )}

    {carCompany && (
      <FormRowReadOnly label={translations.form.carCompany}>
        <ValueText>
          {carCompany.name}
        </ValueText>
      </FormRowReadOnly>
    )}

    {destination && (
      <FormRowReadOnly label={translations.form.destination}>
        <ValueText>
          {destination.name}
        </ValueText>
      </FormRowReadOnly>
    )}
  </>
);


EditReservationForm.propTypes = {
  languageId: string.isRequired,
  optionsReservationTypes: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  translations: object.isRequired,
  form: shape({
    resNo: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resTransportationType: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resReservationType: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resPlatform: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resDate: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resTimeFrom: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resTimeTo: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    resNo: string.isRequired,
    resTransportationType: object.isRequired,
    resReservationType: object.isRequired,
    resPlatform: object,
    resDate: object,
    resTimeFrom: object,
    resTimeTo: object,
  }).isRequired,
  onChange: func.isRequired,
};


export default EditReservationForm;
