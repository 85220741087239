import gql from 'graphql-tag';


/**
 * Common
 */
export const QUERY_DD_TRANSPORTATION_TYPES = gql`
  query FetchAllTransportationTypes($languageId: ID) {
    fetchAllTransportationTypes(languageId: $languageId) {
      id
      name
    }
  }
`;

export const QUERY_GENERIC_FORM = gql`
  query FetchGenericFormConfiguration($languageId: ID, $actionId: ID!, $genericFormId: ID!, $transportationId: ID!, $standId: ID) {
    fetchGenericFormConfiguration(languageId: $languageId, actionId: $actionId, genericFormId: $genericFormId, transportationId: $transportationId, standId: $standId) {
      genericForm {
        id
        name
        structure
        translation
        inputs {
          name
          type
          disabled
          defaultValue
          action
          map
          index
          genericFormDialId
          translation {
            label
            placeholder
            help
          }
          validation {
            required
            min
            max
            regex {
              name
              expression
              code
              params
            }
          }
        }
      }
      genericFormValues
    }
  }
`;


export const QUERY_DROPDOWN = gql`
  query FetchDropDown($languageId: ID, $type: String) {
    fetchDropDown(languageId: $languageId, type: $type) {
      id
      data {
        id
        name
      }
    }
  }
`;


export const QUERY_DD_PLATFORMS = gql`
  query FetchAllPlatforms($languageId: ID, $transportationTypeId: ID) {
    fetchAllPlatforms(languageId: $languageId, transportationTypeId: $transportationTypeId) {
      id
      name
    }
  }
`;


/**
 * PlatformBlocks
 */
export const QUERY_HISTORY = gql`
  query FilterTransportationHistory($languageId: ID, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: transportationHistoryInputFilterParams) {
    filterTransportationHistory(languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
        }
      }
      rows {
        id
        date
        no
        transportationTypeName
        reservationTypeId
        reservationTypeName
        truckRegistrationNumber
        trailerRegistrationNumber
        arrivalState
        clearanceLength
        platformName
        reserved
        arrivalTime
        departureTime
      }
    }
  }
`;


/**
 * Schedule
 */
export const QUERY_TRANSPORTATION_FUTURE = gql`
  query FilterTransportationFuture($languageId: ID, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: transportationFutureInputFilterParams) {
    filterTransportationFuture(languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
        }
      }
      rows {
        id
        no
        date 
        reserved
        companyName
        transportationTypeName
        truckRegistrationNumber
        trailerRegistrationNumber
        platformName
        reservationTimeFrom
        reservationTimeTo
        reservationTypeId
        reservationTypeName
      }
    }
  }
`;


export const QUERY_FILTER_TIMELINE_BY_PLATFORMS = gql`
  query FilterTimelineByPlatforms($languageId: ID, $from: String, $to: String, $platformIds: [ID], $transportationTypeId: ID) {
    filterTimelineByPlatforms(languageId: $languageId, from: $from, to: $to, platformIds: $platformIds, transportationTypeId: $transportationTypeId) {
      filter {
        languageId
        from
        to
        platformIds
        transportationTypeId
      }
      platforms {
        id
        name
        reservation {
          id
          no
          from
          to
          truckRegistrationNumber
          platformId
          platformCode
          platformName
          transportationTypeId
          transportationTypeName
          arrivalState
        }
      }
    }
  }
`;


export const QUERY_FILTER_TIMELINE_BY_TRANSPORTATION_TYPE = gql`
  query FilterTimelineByTransportationType($languageId: ID, $from: String, $to: String, $platformIds: [ID], $transportationTypeId: ID) {
    filterTimelineByTransportationType(languageId: $languageId, from: $from, to: $to, platformIds: $platformIds, transportationTypeId: $transportationTypeId) {
      filter {
        languageId
        from
        to
        platformIds
        transportationTypeId
      }
      transportationTypes {
        id
        name
      }
      reservation {
        id
        no
        from
        to
        truckRegistrationNumber
        platformId
        platformCode
        platformName
        transportationTypeId
        transportationTypeName
        arrivalState
      }
    }
  }
`;


/**
 * Transportation
 */
export const QUERY_TRANSPORTATION_DETAIL = gql`
  query fetchOneTransportation($id: ID, $languageId: ID) {
    fetchOneTransportation(id: $id, languageId: $languageId) {
      id
      reservation {
        resNo: no
        resTransportationType: transportationType {
          id
          name
        }
        resReservationType: reservationType {
          id
          name
        }
        resPlatform: platform {
          id
          name
        }
        resTimeFrom: timeFrom
        resTimeTo: timeTo
        resDate: date
        carQuantity
        carCompanyId
        destinationId
      }
      company {
        compName: name
        compIdentificationNumber: identificationNumber
        compVatNumber: vatNumber
        compStreet: street
        compCity: city
        compZip: zip
        compState: state
        compContactPerson: contactPerson
        compEmail: email
        compPhoneNumber: phoneNumber
      }
      driver {
        driverName: name
        driverPhoneNumber: phoneNumber
        languageId
        languageName
      }
      truck {
        truckRegistrationNumber: registrationNumber
      }
      trailer {
        trailerRegistrationNumber: registrationNumber
      }
      extra {
        note
      }
      prevTransport
      nextTransport
      gateHistoryIn
      gateHistoryOut
      gateHistoryInTime
      gateHistoryOutTime
    }
  }
`;


export const QUERY_TRANSPORTATION_DETAIL_ADMIN = gql`
  query fetchOneTransportation($id: ID, $languageId: ID) {
    fetchOneTransportation(id: $id, languageId: $languageId) {
      id
      reservation {
        resNo: no
        resTransportationType: transportationType {
          id
          name
        }
        resReservationType: reservationType {
          id
          name
        }
        resPlatform: platform {
          id
          name
        }
        resTimeFrom: timeFrom
        resTimeTo: timeTo
        resDate: date
        carQuantity
        carCompanyId
        destinationId
      }
      company {
        compName: name
        compIdentificationNumber: identificationNumber
        compVatNumber: vatNumber
        compStreet: street
        compCity: city
        compZip: zip
        compState: state
        compContactPerson: contactPerson
        compEmail: email
        compPhoneNumber: phoneNumber
      }
      driver {
        driverName: name
        driverPhoneNumber: phoneNumber
        languageId
        languageName
      }
      truck {
        truckRegistrationNumber: registrationNumber
      }
      trailer {
        trailerRegistrationNumber: registrationNumber
      }
      extra {
        note
      }
      prevTransport
      nextTransport
      gateHistoryIn
      gateHistoryOut
      gateHistoryInTime
      gateHistoryOutTime
      gateInPict
      gateOutPict
      gateInOverviewPict
      gateOutOverviewPict
      cargoSpacePictIn
      cargoSpacePictOut
      otherPictsIn
      otherPictsOut
    }
    fetchTransportationActionsTabsConfiguration(languageId: $languageId, transportationId: $id) {
      actionId
      genericFormId
      title
      completed
      start
      end
    }
  }
`;


/**
 * Filters
 */
export const QUERY_ALL_COMPANIES = gql`
  query FetchAllCompanies {
    fetchAllCompanies {
      id
      name
      identificationNumber
    }
  }
`;

export const QUERY_ONE_COMPANY = gql`
  query FetchOneCompany($id: ID) {
    fetchOneCompany(id: $id) {
      id
      name
      email
      info
      contactPerson
      phoneNumber
      identificationNumber
      vatNumber
      street
      city
      zip
      state
    }
  }
`;


export const QUERY_ALL_DRIVERS = gql`
  query FetchAllDrivers {
    fetchAllDrivers {
      id
      name
      phoneNumber
    }
  }
`;


export const QUERY_ALL_TRUCKS = gql`
  query FetchAllTrucks {
    fetchAllTrucks {
      id
      registrationNumber
    }
  }
`;


export const QUERY_SCHEDULE_TIMELINE_FILTER = gql`
  {
    scheduleFilter @client {
      from
      to
      transportationType {
        id
        name
      }
      range {
        id
        value
        name
      }
    }
  }
`;

export const QUERY_RS_TRANSPORTATION_CONFIG = gql`
  query FetchConfigForTransportation {
    fetchConfigForTransportation {
      carCompanies {
        id
        name
      }
      destinations {
        id
        name
      }
      unloadDurations {
        timeInMinutes
        carQuantityFrom
        carQuantityTo
      }
    }
  }
`;

export const QUERY_DD_LANGUAGES = gql`
  query FetchLanguages {
    fetchLanguages {
      id
      name
    }
  }
`;
