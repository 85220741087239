import React, { Fragment, } from 'react';
import {
  arrayOf, shape, func, object, string,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_CREATE_TRANSPORTATION, } from '../../gql/mutations';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Button from '../../../../atoms/Button/Button';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import Cross from '../../../../styles/icons/Cross';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import FormBackground from '../../../../components/Form/FormBackground';
import TransportationTruckForm from '../forms/TransportationTruckForm';
import TransportationDriverForm from '../forms/TransportationDriverForm';
import TransportationCompanyForm from '../forms/TransportationCompanyForm';
import TransportationReservationForm from '../forms/TransportationReservationForm';
import TransportationTrailerForm from '../forms/TransportationTrailerForm';
import CompanyFilter from '../filters/CompanyFilter';
import DriverFilter from '../filters/DriverFilter';
import TruckFilter from '../filters/TruckFilter';
import BlockMoreSegments from './BlockMoreSegments';
import BlockAddReservationForm from './BlockAddReservationForm';
import TransportationExtraForm from '../forms/TransportationExtraForm';


const TransportationCreateView = ({
  // data
  reservationForms,
  detailForm,
  options,
  extraCreateButton,
  languageId,
  translations,
  rsTransportationConfig,
  // methods
  onToggle,
  onChangeReservationForm,
  onChangeDetailForm,
  onAddReservationForm,
  onRemoveReservationForm,
  onCreateExtra,
  onCreateStandard,
  onCreateComplete,
  onCreateError,
  onFillFormValues,
  onAddNewCompany,
}) => {
  const extraCreateButtonBtnProps = (extraCreateButton && extraCreateButton.btnProps)
    ? extraCreateButton.btnProps
    : {};
  const isReservationFormsValid = !reservationForms.some((item) => item.isValid === false);
  const isFormValid = detailForm.isValid && isReservationFormsValid;


  return (
    <FormBackground>

      <Row>
        <Col HD={8} LG={12} MD={24}>

          <BlockMoreSegments
            {...detailForm}
            translations={translations}
            disabled={reservationForms.length < 2}
            onChange={onChangeDetailForm}
          />

          {reservationForms.map((item, index) => (
            <fieldset key={item.formId}>
              <legend>
                <span>{`${index + 1}. ${translations.transportation.titleReservationForm}`}</span>
                {(reservationForms.length > 1) && (
                  <ButtonIcon
                    size="xs"
                    color="error"
                    transparent
                    style={{ marginLeft: '0.25rem', }}
                    onClick={() => onRemoveReservationForm(index)}
                  >
                    <Cross />
                  </ButtonIcon>
                )}
              </legend>
              <TransportationReservationForm
                {...item}
                optionsReservationTypes={options.reservationTypes}
                optionsCarQuantity={options.carQuantity}
                optionTransportationTypeForRS={options.transportationTypeForRS}
                rsTransportationConfig={rsTransportationConfig}
                languageId={languageId}
                translations={translations}
                onChange={(name, value) => onChangeReservationForm(index, name, value)}
              />
            </fieldset>
          ))}

          <BlockAddReservationForm
            translations={translations}
            onAdd={onAddReservationForm}
          />

        </Col>
        <Col HD={8} LG={12} MD={24}>

          <fieldset>
            <legend>{translations.transportation.titleCompanyForm}</legend>
            <CompanyFilter
              onApply={onFillFormValues}
              placeholder={translations.transportation.placeholderFilterCompany}
              translations={translations}
            />
            <TransportationCompanyForm
              {...detailForm}
              translations={translations}
              onChange={onChangeDetailForm}
            />
            <Row>
              <Col textAlign="right">
                <Button
                  color="success"
                  size="sm"
                  onClick={onAddNewCompany}
                >
                  {translations.transportation.btnSaveAsNewCompany}
                </Button>
              </Col>
            </Row>
          </fieldset>

        </Col>
        <Col HD={8} LG={12} MD={24}>

          <fieldset>
            <legend>{translations.transportation.titleDriverForm}</legend>
            <DriverFilter
              onApply={onFillFormValues}
              placeholder={translations.transportation.placeholderFilterDriver}
              translations={translations}
            />
            <TransportationDriverForm
              {...detailForm}
              translations={translations}
              onChange={onChangeDetailForm}
            />
          </fieldset>

          <fieldset>
            <legend>{translations.transportation.titleTruckForm}</legend>
            <TruckFilter
              onApply={onFillFormValues}
              placeholder={translations.transportation.placeholderFilterTruck}
              translations={translations}
            />
            <TransportationTruckForm
              {...detailForm}
              translations={translations}
              onChange={onChangeDetailForm}
            />
          </fieldset>

          <fieldset>
            <legend>{translations.transportation.titleTrailerForm}</legend>
            <TransportationTrailerForm
              {...detailForm}
              translations={translations}
              onChange={onChangeDetailForm}
            />
          </fieldset>

          <fieldset>
            <legend>{translations.transportation.titleExtraForm}</legend>
            <TransportationExtraForm
              {...detailForm}
              translations={translations}
              onChange={onChangeDetailForm}
            />
          </fieldset>

        </Col>
      </Row>

      <Mutation
        mutation={MUTATION_CREATE_TRANSPORTATION}
        onCompleted={onCreateComplete}
        onError={onCreateError}
      >
        {(createMutation, { loading, error, }) => (
          <Fragment>
            <FormError
              isValid={isFormValid}
              customError={detailForm.customError}
              error={error}
            />
            <Row>
              <Col XS={12} SM={12}>
                <Button
                  type="button"
                  shape="outline"
                  onClick={onToggle}
                >
                  {translations.common.back}
                </Button>
              </Col>
              <Col XS={12} SM={12} textAlign="right">
                <ButtonGrp>
                  {extraCreateButton && (
                    <ButtonLoader
                      type="button"
                      color="success"
                      onClick={() => onCreateExtra(createMutation)}
                      disabled={!detailForm.isValid}
                      isLoading={loading}
                      {...extraCreateButtonBtnProps}
                    >
                      {extraCreateButton.text}
                    </ButtonLoader>
                  )}
                  <ButtonLoader
                    type="button"
                    color="success"
                    onClick={() => onCreateStandard(createMutation)}
                    disabled={!detailForm.isValid}
                    isLoading={loading}
                  >
                    {translations.common.create}
                  </ButtonLoader>
                </ButtonGrp>
              </Col>
            </Row>
          </Fragment>
        )}
      </Mutation>
    </FormBackground>
  );
};


TransportationCreateView.propTypes = {
  // data
  reservationForms: arrayOf(object).isRequired,
  detailForm: object.isRequired,
  options: shape({
    reservationTypes: arrayOf(object).isRequired,
    carQuantity: arrayOf(object).isRequired,
    transportationTypeForRS: string.isRequired,
  }).isRequired,
  extraCreateButton: shape({
    text: string.isRequired,
    btnProps: object,
  }),
  languageId: string.isRequired,
  translations: object.isRequired,
  rsTransportationConfig: object.isRequired,
  // methods
  onChangeReservationForm: func.isRequired,
  onChangeDetailForm: func.isRequired,
  onAddReservationForm: func.isRequired,
  onRemoveReservationForm: func.isRequired,
  onToggle: func.isRequired,
  onCreateExtra: func.isRequired,
  onCreateStandard: func.isRequired,
  onCreateError: func.isRequired,
  onCreateComplete: func.isRequired,
  onFillFormValues: func.isRequired,
  onAddNewCompany: func.isRequired,
};

TransportationCreateView.defaultProps = {
  extraCreateButton: undefined,
};


export default TransportationCreateView;
