import React, { Fragment, } from 'react';
import {
  shape, string, func, object, bool,
} from 'prop-types';
import { Mutation, } from 'react-apollo';
import { withTheme, } from 'styled-components';

import { MUTATION_UPDATE_ROLE, } from '../../gql/mutations';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import RoleTree from '../RoleTree/RoleTree';
import FormBackground from '../../../../components/Form/FormBackground';


const RoleUpdateView = ({
  // data
  roleForm: {
    isValid,
    form,
    values,
  },
  theme,
  resourcesValue,
  resources,
  translations,
  languageId,
  // methods
  onChangeForm,
  onChangeResourceValue,
  onClose,
  onUpdate,
  onUpdateCompleted,
  onUpdateError,
}) => (
  <FormBackground>
    <FormRow
      label={translations.form.name}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChangeForm(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <Row formGroup>
      <Col>
        <RoleTree
          // data
          background={theme.grey.t50}
          translations={translations}
          languageId={languageId}
          resourcesValue={resourcesValue}
          // methods
          onChangeResourceValue={onChangeResourceValue}
        />
      </Col>
    </Row>

    <Mutation
      mutation={MUTATION_UPDATE_ROLE}
      onCompleted={onUpdateCompleted}
      onError={onUpdateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col SM={12} MD={12} textAlign="left">
              <Button
                onClick={onClose}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col SM={12} MD={12} textAlign="right">
              {resources.settings_users && (
                <ButtonLoader
                  color="success"
                  onClick={() => onUpdate(mutate)}
                  disabled={!isValid}
                  isLoading={loading}
                >
                  {translations.common.update}
                </ButtonLoader>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


RoleUpdateView.propTypes = {
  theme: shape({
    grey: object.isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resourcesValue: object.isRequired,
  resources: object.isRequired,
  roleForm: shape({
    isValid: bool.isRequired,
    form: shape({
      name: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
    }).isRequired,
    values: shape({
      name: string,
    }).isRequired,
  }).isRequired,
  onChangeForm: func.isRequired,
  onChangeResourceValue: func.isRequired,
  onClose: func.isRequired,
  onUpdate: func.isRequired,
  onUpdateCompleted: func.isRequired,
  onUpdateError: func.isRequired,
};


export default withTheme(RoleUpdateView);
