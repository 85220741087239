import gql from 'graphql-tag';


/**
 * Users
 */
export const QUERY_USERS_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            history
            filter
            home
            update
            delete
            yes
            no
            from
            to
            greaterThan
            lessThan
            rsUsers
            back
          }
          rs {
            users {
              driver
              dispatcher
              addUser
              user
              blocked
              role
              deleteModalTitle
              deleteModalText
              deletePlanTransportationModalText
              deleteNotFinishedTransportationModalText
              lockButtonTitle
              lockModalTitle
              lockModalText
              lockModalButtonText
              lockModalButtonCompletedText
              language
            }
          }
        }
      }
    }
  }
`;

/**
 * Blocking days
 */
export const QUERY_PLATFORM_BLOCKS_TRANSLATIONS = gql`
query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            filter
            home
            delete
            detail
            from
            to
            rsPlatformBlocks
          }
          rs {
            platformBlocks {
              deleteReservationModalTitle
              deleteReservationModalText
              deletePlatformBlockModalTitle
              deletePlatformBlockModalText
              daySelect
              date
              reservationOverviewTitle
              available
              partiallyBlocked
              blocked
              unavailable
              platformSelect
              blockFrom
              blockTo
              blockButtonText
            }
          }
          form {
            truckRegistrationNo
            arrivalTime
          }
          transportation {
            historyEmpty
            thActions
          }
        }
        shared {
          calendar {
            dayShortSu
            dayShortMo
            dayShortTu
            dayShortWe
            dayShortTh
            dayShortFr
            dayShortSa
            january
            february
            march
            april
            may
            june
            july
            august
            september
            october
            november
            december
          }
        }
      }
    }
  }
`;

export const QUERY_USER_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            update
            created
            updated
          }
          rs {
            users {
              createModalUserTitle
              updateModalUserTitle
              driver
              dispatcher
            }
          }
          form {
            email
            phoneNumber
            roles
            company
            language
          }
        }
      }
    }
  }
`;

/**
 * Transportation
 */
export const TRANSLATIONS_TRANSPORTATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            close
          }
          transportation {
            detailTransportationModalTitle
            titleReservationForm
            titleCompanyForm
            titleDriverForm
            titleTruckForm
            titleTrailerForm
          }
          form {
            personName
            transportationType
            reservationType
            date
            platform
            reservationNumber
            truckRegistrationNumber
            phoneNumber
            name
            contactPerson
            email
            identificationNumber
            vatNumber
            street
            city
            zip
            state
            timeWindow
          }
        }
      }
    }
  }
`;
