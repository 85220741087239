import React, { Component, } from 'react';
import {
  string, shape, object, func,
} from 'prop-types';

import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import TransportationView from './TransportationView';
import ModalFinish from '../ModalFinish/ModalFinish';
import ModalForward from '../ModalForward/ModalForward';
import ModalInterrupt from '../ModalInterrupt/ModalInterrupt';
import ModalDetail from '../ModalDetail/ModalDetail';
import TransportationDetail from '../../../../Transportation/components/TransportationDetail/TransportationDetail';
import ModalMove from '../ModalMove/ModalMove';


// TODO get from settings
const REPEAT_CALL_DELAY = 15;


class Transportation extends Component {
  constructor(props) {
    super(props);

    this.repeatCallDelayTimer = null;

    const {
      transportation: { lastCall, },
    } = props;

    // set timer to block repeat call
    if (REPEAT_CALL_DELAY !== null && lastCall !== null) {
      const now = new Date();
      const last = new Date(lastCall);
      const diff = now - last;

      if (diff < REPEAT_CALL_DELAY * 1000) {
        this.repeatCallDelayTimer = setTimeout(
          this.handleRepeatCallTimerDone,
          (REPEAT_CALL_DELAY * 1000) - diff,
        );
      }
    }

    this.state = {
      repeatCall: {
        disabled: this.repeatCallDelayTimer !== null,
      },
      detailModal: {
        isOpen: false,
      },
      transportationDetailModal: {
        isOpen: false,
      },
      finishModal: {
        isOpen: false,
      },
      interruptModal: {
        isOpen: false,
      },
      forwardModal: {
        isOpen: false,
      },
      moveModal: {
        isOpen: false,
      },
    };
  }


  componentWillUnmount() {
    this.handleRepeatCallClear();
  }


  /**
   * Detail modal
   */
  handleToggleDetailModal = () => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
      },
    }));
  }

  /**
   * Detail modal
   */
  handleToggleTransportationDetailModal = () => {
    this.setState((prevState) => ({
      transportationDetailModal: {
        ...prevState.transportationDetailModal,
        isOpen: !prevState.transportationDetailModal.isOpen,
      },
    }));
  }

  /**
   * Finish modal
   */
  handleToggleFinishModal = () => {
    this.setState((prevState) => ({
      finishModal: {
        ...prevState.finishModal,
        isOpen: !prevState.finishModal.isOpen,
      },
    }));
  }


  /**
   * Interrupt modal
   */
  handleToggleInterruptModal = () => {
    this.setState((prevState) => ({
      interruptModal: {
        ...prevState.interruptModal,
        isOpen: !prevState.interruptModal.isOpen,
      },
    }));
  }


  /**
   * Forward modal
   */
  handleToggleForwardModal = () => {
    this.setState((prevState) => ({
      forwardModal: {
        ...prevState.forwardModal,
        isOpen: !prevState.forwardModal.isOpen,
      },
    }));
  }

  /**
   * Move modal
   */
  handleToggleMoveModal = () => {
    this.setState((prevState) => ({
      moveModal: {
        ...prevState.moveModal,
        isOpen: !prevState.moveModal.isOpen,
      },
    }));
  }


  /**
   * Clear
   */
  handleClearTransportation = (mutationClear) => {
    const {
      transportation: {
        platformId,
        transportationId,
      },
    } = this.props;

    mutationClear({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Clear - Completed
   */
  handleClearTransportationCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleClearedSuccess,
    });
  }

  /**
   * Clear - Error
   */
  handleClearTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleClearedError,
    });
  }


  /**
   * Repeat Call
   */
  handleRepeatCallTransportation = (mutationRepeatCall) => {
    const {
      transportation: {
        platformId,
        transportationId,
      },
    } = this.props;

    // Repeat call delay
    if (this.repeatCallDelayTimer !== null) return;

    mutationRepeatCall({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Repeat Call - Completed
   */
  handleRepeatCallTransportationCompleted = () => {
    const { translations, addNotification, } = this.props;

    this.handelRepeatCallStart();

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleRepeatCallSuccess,
    });
  }

  /**
   * Repeat Call - Error
   */
  handleRepeatCallTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleRepeatCallError,
    });
  }

  /**
   * Repeat Call - Start Timer
   */
  handelRepeatCallStart = () => {
    this.handleRepeatCallClear();
    this.repeatCallDelayTimer = setTimeout(
      this.handleRepeatCallTimerDone, REPEAT_CALL_DELAY * 1000
    );

    this.setState((prevState) => ({
      repeatCall: {
        ...prevState.repeatCall,
        disabled: true,
      },
    }));
  }

  /**
   * Repeat Call - Clear Timer
   */
  handleRepeatCallClear = () => {
    if (this.repeatCallDelayTimer !== null) {
      clearTimeout(this.repeatCallDelayTimer);
      this.repeatCallDelayTimer = null;
    }
  }

  /**
   * Repeat Call - Done Timer
   */
  handleRepeatCallTimerDone = () => {
    clearTimeout(this.repeatCallDelayTimer);
    this.repeatCallDelayTimer = null;

    this.setState((prevState) => ({
      repeatCall: {
        ...prevState.repeatCall,
        disabled: false,
      },
    }));
  }


  /**
   * Cancel
   */
  handleCancelTransportation = (mutationCancel) => {
    const {
      transportation: {
        platformId,
        transportationId,
      },
    } = this.props;

    mutationCancel({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Cancel - Completed
   */
  handleCancelTransportationCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleCancelSuccess,
    });
  }

  /**
   * Cancel - Error
   */
  handleCancelTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleCancelError,
    });
  }


  /**
   * Continue
   */
  handleContinueTransportation = (mutationContinue) => {
    const {
      transportation: {
        platformId,
        transportationId,
      },
    } = this.props;

    mutationContinue({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Continue - Completed
   */
  handleContinueTransportationCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleContinueSuccess,
    });
  }

  /**
   * Continue - Error
   */
  handleContinueTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleContinueError,
    });
  }


  /**
   * Finish
   */
  handleFinishTransportation = (mutationFinish) => {
    const {
      transportation: {
        platformId,
        transportationId,
      },
    } = this.props;

    mutationFinish({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Finish - Completed
   */
  handleFinishTransportationCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleFinishSuccess,
    });
  }

  /**
   * Finish - Error
   */
  handleFinishTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleFinishError,
    });
  }


  render() {
    const {
      repeatCall,
      finishModal,
      detailModal,
      transportationDetailModal,
      interruptModal,
      forwardModal,
      moveModal,
    } = this.state;
    const {
      transportation,
      languageId,
      translations,
      translationsCommon,
      resources,
    } = this.props;

    return (
      <>

        {finishModal.isOpen && (
          <ModalFinish
            // data
            platformId={transportation.platformId}
            transportationId={transportation.transportationId}
            transportation={transportation}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleFinishModal}
            onDetail={this.handleToggleDetailModal}
          />
        )}

        {forwardModal.isOpen && (
          <ModalForward
            // data
            platformId={transportation.platformId}
            transportationId={transportation.transportationId}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleForwardModal}
          />
        )}

        {moveModal.isOpen && (
          <ModalMove
            // data
            platformId={transportation.platformId}
            transportationId={transportation.transportationId}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleMoveModal}
          />
        )}

        {interruptModal.isOpen && (
          <ModalInterrupt
            // data
            platformId={transportation.platformId}
            transportationId={transportation.transportationId}
            translations={translations}
            // methods
            onToggle={this.handleToggleInterruptModal}
          />
        )}

        {detailModal.isOpen && (
          <ModalDetail
            // data
            transportationId={transportation.transportationId}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleDetailModal}
          />
        )}

        {transportationDetailModal.isOpen && (
          <TransportationDetail
            // data
            transportationId={transportation.transportationId}
            fromClearing
            // functions
            onToggle={this.handleToggleTransportationDetailModal}
          />
        )}

        <TransportationView
          transportation={transportation}
          repeatCall={repeatCall}
          translations={translations}
          translationsCommon={translationsCommon}
          resources={resources}
          // detail
          onDetail={this.handleToggleDetailModal}
          // transportation detail
          onTransportationDetail={this.handleToggleTransportationDetailModal}
          // forward
          onForward={this.handleToggleForwardModal}
          // finish
          onFinish={this.handleToggleFinishModal}
          // interrupt
          onInterrupt={this.handleToggleInterruptModal}
          // move
          onMove={this.handleToggleMoveModal}
          // clear
          onClearTransportation={this.handleClearTransportation}
          onClearTransportationCompleted={this.handleClearTransportationCompleted}
          onClearTransportationError={this.handleClearTransportationError}
          // repeat call
          onRepeatCallTransportation={this.handleRepeatCallTransportation}
          onRepeatCallTransportationCompleted={this.handleRepeatCallTransportationCompleted}
          onRepeatCallTransportationError={this.handleRepeatCallTransportationError}
          // cancel
          onCancelTransportation={this.handleCancelTransportation}
          onCancelTransportationCompleted={this.handleCancelTransportationCompleted}
          onCancelTransportationError={this.handleCancelTransportationError}
          // continue
          onContinueTransportation={this.handleContinueTransportation}
          onContinueTransportationCompleted={this.handleContinueTransportationCompleted}
          onContinueTransportationError={this.handleContinueTransportationError}
          // Finish
          onFinishTransportation={this.handleFinishTransportation}
          onFinishTransportationCompleted={this.handleFinishTransportationCompleted}
          onFinishTransportationError={this.handleFinishTransportationError}
        />

      </>
    );
  }
}


Transportation.propTypes = {
  transportation: shape({
    id: string.isRequired,
    lastCall: string,
    platformId: string.isRequired,
    transportationId: string.isRequired,
  }).isRequired,
  resources: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(Transportation);
