// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import styled from 'styled-components';
import {
  arrayOf, bool, object, number,
} from 'prop-types';

import Table from '../../../../atoms/Table/Table';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import {
  ColumnStyleDefault,
  findConditionalConfig,
} from '../../utils/config';
import { getMetricTranslation, } from '../../utils/translation';
import { createFieldOrDefault, } from '../../../../logic/object';
import ProgressUp from '../../../../styles/icons/ProgressUp';
import ProgressDown from '../../../../styles/icons/ProgressDown';
import ProgressConst from '../../../../styles/icons/ProgressConst';
import { convertToDuration, } from '../../utils/data';


const Header = ({ columns, }) => (
  <thead>
    <tr>
      <th>
        Období
      </th>
      {columns.map((column) => (
        <th key={`th-${column.key}`}>
          {getMetricTranslation(column.key, column.usageLongDiffName)}
        </th>
      ))}
    </tr>
  </thead>
);

Header.propTypes = {
  columns: arrayOf(object.isRequired).isRequired,
};


const StyledCell = styled.span`
  ${(p) => p.backgroundColor && `
    border-radius: 1rem;
    background: ${p.backgroundColor};
  `}
  
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  
  width: 6rem;
  height: 2rem;
  color: ${(p) => p.textColor};
  
  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
`;

const printValue = (value, format) => {
  if (format === 'duration') {
    return convertToDuration(value);
  }
  return Math.floor(value);
};

const Cell = ({ column, value, prevValue, }) => {
  const config = findConditionalConfig(column.style, value, prevValue);
  const getField = createFieldOrDefault(config, ColumnStyleDefault);

  const color = getField('textColor');
  const iconConfig = getField('icon');
  let icon = null;

  if (iconConfig === 'PROGRESS_UP') {
    icon = <ProgressUp fill={color} />;
  } else if (iconConfig === 'PROGRESS_DOWN') {
    icon = <ProgressDown fill={color} />;
  } else if (iconConfig === 'PROGRESS_CONST') {
    icon = <ProgressConst fill={color} />;
  }

  return (
    <StyledCell
      textColor={color}
      backgroundColor={getField('backgroundColor')}
    >
      {icon}
      <span className="value">
        {printValue(value, column.format)}
      </span>
    </StyledCell>
  );
};

Cell.propTypes = {
  column: object.isRequired,
  value: number.isRequired,
  prevValue: number,
};

Cell.defaultProps = {
  prevValue: undefined,
};

const Rows = (props) => {
  const {
    loading, error, data, columns,
  } = props;

  if (loading) {
    return (
      <TableLoading
        colsCount={columns.length + 1}
        rowsCount={3}
      />
    );
  }

  if (error ?? data.length < 1) {
    return (
      <TableError
        colsCount={columns.length + 1}
        error={error}
      />
    );
  }

  return (
    <tbody>
      {data.map((metric) => (
        <tr key={`row-${metric.name}`}>
          <td>
            {metric.name}
          </td>
          {columns.map((column) => (
            <td key={`td-${column.key}`}>
              <Cell
                column={column}
                value={metric[column.key]}
                prevValue={metric[`${column.key}Prev`]}
              />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: arrayOf(object.isRequired).isRequired,
  columns: arrayOf(object.isRequired).isRequired,
};

Rows.defaultProps = {
  error: undefined,
};

const ReportTable = ({ config, data, }) => (
  <>
    {config.title ? <h3>{config.title}</h3> : null}
    <Table className="report-table" fillContent>
      <Header columns={config.columns} />
      <Rows
        loading={false}
        error={undefined}
        data={(JSON.parse(JSON.stringify(data))).sort((a, b) => (a.fromDate < b.fromDate ? 1 : -1))}
        columns={config.columns}
      />
    </Table>
  </>
);

ReportTable.propTypes = {
  config: object.isRequired,
  data: arrayOf(object.isRequired).isRequired,
};


export default ReportTable;
