import gql from 'graphql-tag';

/**
 * Table queries
 */
export const QUERY_GATE_RECORDS = gql`
  query FilterGatehouseRecords($section: String!, $languageId: ID!, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: gateRecordInputFilterParams) {
    filterGatehouseRecords(section: $section, languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
      }
      rows {
        id
        gateType
        time
        registrationNumber
        vehicleGroupId
        vehicleGroupName
        driverName
        driverPhoneNumber
      }
    }
  }
`;

export const QUERY_GATE_RECORD_DETAIL = gql`
  query fetchOneGatehouseRecordRecord($id: ID, $languageId: ID) {
    fetchOneGatehouseRecord(id: $id, languageId: $languageId) {
      id
      vehicleGroupId
      vehicleGroupName
      registrationNumber
      gateType
      time
      pictures
      form_in
      form_out
      driverName
      driverPhoneNumber
    }
  }
`;
