import React, { Component, } from 'react';
import {
  func, shape, bool, object, string,
} from 'prop-types';

import { QUERY_VEHICLE_GROUPS_DETAIL_TRANSLATIONS, } from '../../gql/translations';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initVehicleGroupForm, } from '../forms/structure';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import VehicleGroupCreateView from './VehicleGroupCreateView';


class VehicleGroupCreate extends Component {
  state = {
    ...initVehicleGroupForm,
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * onCreate
   */
  handleCreate = (mutate) => {
    const { detailForm, detailForm: { values, }, } = this.state;
    const { languageId, } = this.props;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const variables = {
        name: values.name,
        configuration: {
          freeEntrance: values.freeEntrance === null ? false : values.freeEntrance,
          freeExit: values.freeExit === null ? false : values.freeExit,
          blacklist: values.blacklist === null ? false : values.blacklist,
          customs: values.customs === null ? false : values.customs,
          internalTransport: values.internalTransport === null ? false : values.internalTransport,
          registeredTransport: values.registeredTransport === null ? false : values.registeredTransport,
          internalTraffic: values.internalTraffic === null ? false : values.internalTraffic,
        },
        languageId,
      };
      mutate({
        variables,
      });
    }
  }


  /**
   * onCreate - Completed
   */
  handleCreateComplete = (responseData) => {
    const {
      translations, onToggle, onCreated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });

    if (onCreated) onCreated(responseData);
  }


  /**
   * onCreate - Error
   */
  handleCreateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const {
      translations, modalData: { isOpen, }, onToggle, languageId,
    } = this.props;

    if (!isOpen) return null;

    return (
      <Modal
        title={translations.directory.titleCreateModalVehicleGroup}
        isOpen
        onClose={onToggle}
        size="SM"
        disablePadding
      >
        <VehicleGroupCreateView
          detailForm={detailForm}
          translations={translations}
          languageId={languageId}
          onChange={this.handleValueChange}
          onToggle={onToggle}
          onCreate={this.handleCreate}
          onCreateComplete={this.handleCreateComplete}
          onCreateError={this.handleCreateError}
        />
      </Modal>
    );
  }
}


VehicleGroupCreate.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  modalData: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
  onCreated: func,
  addNotification: func.isRequired,
};

VehicleGroupCreate.defaultProps = {
  onCreated: undefined,
};


export default pipe(
  withNotifications,
  withTranslations(QUERY_VEHICLE_GROUPS_DETAIL_TRANSLATIONS),
)(VehicleGroupCreate);
