import gql from 'graphql-tag';


/**
 * Notifications
 */
export const QUERY_NOTIFICATIONS = gql`
  query FetchNotifications($languageId: ID) {
    fetchNotifications(languageId: $languageId) {
      id
      info
      notificationType
      type
      timestamp
      acknowledged
      acknowledgeTime
      transportationId
      transportation
      standId
      stand
    }
  }
`;


/**
 * Monitoring
 */
export const QUERY_LIVE_MONITORING_TRANSPORTATIONS = gql`
  query FetchLiveTransportation($state: String, $languageId: ID) {
    fetchLiveTransportation(state: $state, languageId: $languageId) {
      id
      no
      state
      transportationTypeName
      truckRegistration
      trailerRegistration
      delayed
      date
      reservationTimeFrom
      reservationTimeTo
      reservationTypeId
      reservationTypeName
      platformId
      platformName
      clearanceState
      customs
      note
      notArrivedInLimit
    }
  }
`;
