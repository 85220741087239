import styled from 'styled-components';


const StyledPlatforms = styled.div`
  .storekeeperPlatforms--list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25rem;
  }

  .storekeeperPlatforms--item {
    width: 28rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    
  }


  /* PLATFORM */


  .storekeeperPlatform--platform {
    background: ${(p) => p.theme.white};
    box-shadow: 6px 3px 12px 0 rgba(0, 0, 0, 0.05);
  }

  .storekeeperPlatform--platform-type-panel {
    height: 25px;
    display: flex;
  }
  
  .storekeeperPlatform--platform-type-panel-column {
    width: 50%;
  }

  .storekeeperPlatform--platform-type-panel-column-load, .storekeeperPlatform--platform-type-panel-column-mixed:first-child {
    background-color: #822C30;
  }

  .storekeeperPlatform--platform-type-panel-column-unload, .storekeeperPlatform--platform-type-panel-column-mixed {
    background-color: #A8DF24;
  }
  
  .storekeeperPlatform--platform-panel {
    padding: 1rem;
  }

  .storekeeperPlatform--platform-panel-top {
    display: flex;
    align-items: center;
  }

  .storekeeperPlatform--platform-panel-sign {
    width: 0.5rem;
    min-width: 0.5rem;
    max-width: 0.5rem;
    background: ${(p) => p.theme.grey.t800};
    height: 1.25rem;
  }

  .storekeeperPlatform--platform-panel-sign-opened {
    background: ${(p) => p.theme.success.t800};
  }

  .storekeeperPlatform--platform-panel-sign-closed {
    background: ${(p) => p.theme.error.t800};
  }

  .storekeeperPlatform--platform-panel-title {
    margin-left: 0.25rem;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-size: 1rem;
  }

  .storekeeperPlatform--platform-panel-btnWrapper {
    margin-left: auto;
  }

  .storekeeperPlatform--platform-panel-btnWrapper-btn {
    
  }

  .storekeeperPlatform--platform-panel-capacity {
    font-size: 0.8rem;
    color: ${(p) => p.theme.grey.t700};
  }
`;


export default StyledPlatforms;
