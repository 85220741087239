import React, { Fragment, } from 'react';
import {
  shape, string, func, object, arrayOf,
} from 'prop-types';

import { Query, } from 'react-apollo';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import TextArea from '../../../../atoms/TextArea/TextArea';
import { QUERY_ALL_TRUCKS, } from '../../../Transportation/gql/queries';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';


const DriverForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.personName}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.phoneNumber}
      input={form.phoneNumber}
    >
      <Input
        type="text"
        value={values.phoneNumber}
        onChange={(e) => onChange(form.phoneNumber.name, e.target.value)}
        status={form.phoneNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.info}
      input={form.info}
    >
      <TextArea
        type="text"
        value={values.info}
        onChange={(e) => onChange(form.info.name, e.target.value)}
        status={form.info.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.trucks}
      input={form.trucks}
    >
      <Query query={QUERY_ALL_TRUCKS}>
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.trucks}
            options={data.fetchAllTrucks ? data.fetchAllTrucks : []}
            onChange={(options) => onChange(form.trucks.name, options === null ? [] : options)}
            status={form.trucks.status}
            getOptionLabel={(option) => option.registrationNumber}
            getOptionValue={(option) => option.id}
            isMulti
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

  </Fragment>
);


DriverForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    phoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    info: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    trucks: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    phoneNumber: string,
    info: string,
    trucks: arrayOf(shape({
      id: string.isRequired,
      registrationNumber: string.isRequired,
    })).isRequired,
  }).isRequired,
  onChange: func.isRequired,
};


export default DriverForm;
