import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import TextArea from '../../../../atoms/TextArea/TextArea';


const TransportationExtraForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.notice}
      input={form.note}
    >
      <TextArea
        type="text"
        value={values.note}
        onChange={(e) => onChange(form.note.name, e.target.value)}
        status={form.note.status}
      />
    </FormRow>

  </Fragment>
);


TransportationExtraForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    note: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    note: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default TransportationExtraForm;
