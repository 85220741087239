import { INPUT_TYPE, } from 'sofair-form-validation';

import { initForm, initInput, } from '../../../../logic/form/common';
import { regexEmail, regexPhone, } from '../../../../logic/form/regexes';


/**
 * Transportation - Reservation
 */
export const initTransportationReservationForm = {
  ...initForm,
  form: {
    resNo: initInput({
      name: 'resNo',
      validation: {
        required: true,
      },
    }),
    resTransportationType: initInput({
      name: 'resTransportationType',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
    resReservationType: initInput({
      name: 'resReservationType',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
    resPlatform: initInput({
      name: 'resPlatform',
      type: INPUT_TYPE.SELECT,
    }),
    resDate: initInput({
      name: 'resDate',
      type: INPUT_TYPE.DATE,
    }),
    resTimeFrom: initInput({
      name: 'resTimeFrom',
      type: INPUT_TYPE.DATE,
    }),
    resTimeTo: initInput({
      name: 'resTimeTo',
      type: INPUT_TYPE.DATE,
    }),
    carQuantity: initInput({
      name: 'carQuantity',
      type: INPUT_TYPE.SELECT,
    }),
    carCompany: initInput({
      name: 'carCompany',
      type: INPUT_TYPE.SELECT,
    }),
    destination: initInput({
      name: 'destination',
      type: INPUT_TYPE.SELECT,
    }),
  },
  values: {
    resNo: '',
    resTransportationType: null,
    resReservationType: null,
    resPlatform: null,
    resDate: null,
    resTimeFrom: null,
    resTimeTo: null,
    carQuantity: null,
    carCompany: null,
    destination: null,
  },
};


const initMoreSegmentsForm = {
  ...initForm,
  form: {
    autoStartOnCombinedTransportation: initInput({
      name: 'autoStartOnCombinedTransportation',
      type: INPUT_TYPE.CHECKBOX,
    }),
  },
  values: {
    autoStartOnCombinedTransportation: false,
  },
};


/**
 * Transportation - Company
 */
const initTransportationCompanyForm = {
  ...initForm,
  form: {
    compName: initInput({
      name: 'compName',
      validation: {
        min: 2,
        max: 255,
      },
    }),
    compIdentificationNumber: initInput({
      name: 'compIdentificationNumber',
    }),
    compVatNumber: initInput({
      name: 'compVatNumber',
    }),
    compStreet: initInput({
      name: 'compStreet',
    }),
    compCity: initInput({
      name: 'compCity',
    }),
    compZip: initInput({
      name: 'compZip',
    }),
    compState: initInput({
      name: 'compState',
    }),
    compContactPerson: initInput({
      name: 'compContactPerson',
    }),
    compEmail: initInput({
      name: 'compEmail',
      validation: {
        regex: regexEmail,
      },
    }),
    compPhoneNumber: initInput({
      name: 'compPhoneNumber',
      validation: {
        regex: regexPhone,
      },
    }),
  },
  values: {
    compName: '',
    compIdentificationNumber: '',
    compVatNumber: '',
    compStreet: '',
    compCity: '',
    compZip: '',
    compState: '',
    compContactPerson: '',
    compEmail: '',
    compPhoneNumber: '',
  },
};


/**
 * Transportation - Driver
 */
const initTransportationDriverForm = {
  ...initForm,
  form: {
    driverName: initInput({
      name: 'driverName',
    }),
    driverPhoneNumber: initInput({
      name: 'driverPhoneNumber',
      validation: {
        regex: regexPhone,
      },
    }),
    languageId: initInput({
      name: 'languageId',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
  },
  values: {
    driverName: '',
    driverPhoneNumber: '',
    languageId: null,
  },
};


/**
 * Transportation - Truck
 */
const initTransportationTruckForm = {
  ...initForm,
  form: {
    truckRegistrationNumber: initInput({
      name: 'truckRegistrationNumber',
      validation: {
        max: 20,
      },
    }),
  },
  values: {
    truckRegistrationNumber: '',
  },
};


const initTrailerTruckForm = {
  ...initForm,
  form: {
    trailerRegistrationNumber: initInput({
      name: 'trailerRegistrationNumber',
      validation: {
        max: 20,
      },
    }),
  },
  values: {
    trailerRegistrationNumber: '',
  },
};


const initExtraForm = {
  ...initForm,
  form: {
    note: initInput({
      name: 'note',
    }),
  },
  values: {
    note: '',
  },
};


export const initCreateTransportationForm = {
  ...initForm,
  form: {
    ...initMoreSegmentsForm.form,
    ...initTransportationCompanyForm.form,
    ...initTransportationDriverForm.form,
    ...initTransportationTruckForm.form,
    ...initTrailerTruckForm.form,
    ...initExtraForm.form,
  },
  values: {
    ...initTransportationCompanyForm.values,
    ...initMoreSegmentsForm.values,
    ...initTransportationDriverForm.values,
    ...initTransportationTruckForm.values,
    ...initTrailerTruckForm.values,
    ...initExtraForm.values,
  },
};


export const initTransportationForm = {
  ...initForm,
  form: {
    ...initTransportationReservationForm.form,
    ...initTransportationCompanyForm.form,
    ...initTransportationDriverForm.form,
    ...initTransportationTruckForm.form,
    ...initTrailerTruckForm.form,
    ...initExtraForm.form,
  },
  values: {
    ...initTransportationReservationForm.values,
    ...initTransportationCompanyForm.values,
    ...initTransportationDriverForm.values,
    ...initTransportationTruckForm.values,
    ...initTrailerTruckForm.values,
    ...initExtraForm.values,
  },
};


export const customValidationReservationForm = (transportationTypeForRS) => (form, values) => {
  const {
    resTimeFrom, resTimeTo, resTransportationType, carQuantity, carCompany, destination,
  } = values;
  if (resTimeFrom && resTimeTo) {
    if (resTimeFrom >= resTimeTo) {
      return {
        code: 'CUSTOM_ERROR_FROM_GREATER_THAN_TO',
        params: {},
      };
    }
  }

  if (resTransportationType.id === transportationTypeForRS) {
    if (!carQuantity || !carCompany || !destination) {
      return {
        code: 'MISSING_REQUIRED_FIELD_FOR_RS_TRANSPORTATION',
        params: {},
      };
    }
  }

  return null;
};
