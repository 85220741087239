import React from 'react';
import { createRoot, } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import { unregister, } from './serviceWorker';

import './index.css';

document.title = 'QTir - central app';

// TODO: remake scrolling in time input - polyfill: scrollTo doesn't work on Edge
smoothscroll.polyfill();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

unregister();
