import React from 'react';
import {
  shape, string, object, func,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_CALL_TRANSPORTATION, MUTATION_CHANGE_STOREKEEPER_QUEUE, } from '../../../gql/mutations';
import { QUEUE_TABS, } from '../../Queue/Queue';
import Button from '../../../../../atoms/Button/Button';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';


const PlatformSectionEmpty = ({
  // data
  platform: {
    id,
    name,
  },
  translations,
  // functions
  onCallTransportation,
  onCallTransportationCompleted,
  onCallTransportationError,
}) => (
  <div className="storekeeperPlatformSection--empty">

    <div className="storekeeperPlatformSection--empty-text">
      {translations.centralAppStorekeeper.platformFreeSpace}
    </div>

    <div>
      <Mutation
        mutation={MUTATION_CHANGE_STOREKEEPER_QUEUE}
        variables={{
          tab: QUEUE_TABS.PLATFORM,
          id,
          name,
        }}
      >
        {(mutationCallPlatform) => (
          <Button
            color="tertiary"
            onClick={() => onCallTransportation(mutationCallPlatform)}
            paddingHalf
          >
            {translations.centralAppStorekeeper.btnPlatformCallQueue}
          </Button>
        )}
      </Mutation>
      <Mutation
        mutation={MUTATION_CALL_TRANSPORTATION}
        onCompleted={onCallTransportationCompleted}
        onError={onCallTransportationError}
      >
        {(mutationCall, { loading, }) => (
          <ButtonLoader
            className="storekeeperPlatformSection--empty-btnCall"
            color="success"
            onClick={() => onCallTransportation(mutationCall)}
            isLoading={loading}
            paddingHalf
          >
            {translations.centralAppStorekeeper.btnPlatformCall}
          </ButtonLoader>
        )}
      </Mutation>
    </div>

  </div>
);


PlatformSectionEmpty.propTypes = {
  platform: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  translations: object.isRequired,
  onCallTransportation: func.isRequired,
  onCallTransportationCompleted: func.isRequired,
  onCallTransportationError: func.isRequired,
};


export default PlatformSectionEmpty;
