import React, { Fragment, } from 'react';
import {
  arrayOf, string, object, func, bool,
} from 'prop-types';

import { Mutation, } from 'react-apollo';
import Button from '../../../../atoms/Button/Button';
import InfoView from './InfoView';
import FlowChart from './FlowChart';

import StyledTransportationDetail from './styles/StyledTransportationDetail';
import { MUTATION_EDIT_DETAIL_TRANSPORTATION, } from '../../gql/mutations';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';

const mergePictures = (detail, type) => {
  const {
    gateInPict,
    gateOutPict,
    gateInOverviewPict,
    gateOutOverviewPict,
    cargoSpacePictIn,
    cargoSpacePictOut,
    otherPictsIn,
    otherPictsOut,
  } = detail;

  const pictures = [];

  if (type === 'in') {
    if (gateInPict) {
      pictures.push(gateInPict);
    }
    if (gateInOverviewPict) {
      pictures.push(gateInOverviewPict);
    }
    if (cargoSpacePictIn) {
      pictures.push(cargoSpacePictIn);
    }
    if (otherPictsIn) {
      pictures.push(...otherPictsIn);
    }
  } else {
    if (gateOutPict) {
      pictures.push(gateOutPict);
    }
    if (gateOutOverviewPict) {
      pictures.push(gateOutOverviewPict);
    }
    if (cargoSpacePictOut) {
      pictures.push(cargoSpacePictOut);
    }
    if (otherPictsOut) {
      pictures.push(...otherPictsOut);
    }
  }

  return pictures;
};

const TransportationDetailView = ({
  // data
  detail,
  tabs,
  transportationId,
  languageId,
  translations,
  rsTransportationConfig,
  fromClearing,
  detailForm,
  // methods
  onToggle,
  onEdit,
  onEditComplete,
  onEditError,
  onChangeForm,
}) => (
  <StyledTransportationDetail>

    <InfoView
      {...detail}
      translations={translations}
      rsTransportationConfig={rsTransportationConfig}
      fromClearing={fromClearing}
      detailForm={detailForm}
      onChangeForm={onChangeForm}
    />

    <FlowChart
      tabs={tabs}
      transportationId={transportationId}
      translations={translations}
      languageId={languageId}
      gateIn={detail.gateHistoryIn}
      gateOut={detail.gateHistoryOut}
      gateHistoryInTime={detail.gateHistoryInTime}
      gateHistoryOutTime={detail.gateHistoryOutTime}
      picturesIn={mergePictures(detail, 'in')}
      picturesOut={mergePictures(detail, 'out')}
    />

    <Mutation
      mutation={MUTATION_EDIT_DETAIL_TRANSPORTATION}
      onCompleted={onEditComplete}
      onError={onEditError}
    >
      {(editMutation, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            customError={detailForm.customError}
            error={error}
          />
          <ButtonGrp block>
            <Button
              shape="outline"
              onClick={onToggle}
            >
              {translations.common.back}
            </Button>
            {fromClearing && (
              <ButtonLoader
                color="success"
                onClick={() => onEdit(editMutation)}
                isLoading={loading}
                className="buttonGrp--right"
              >
                {translations.common.update}
              </ButtonLoader>
            )}
          </ButtonGrp>
        </Fragment>
      )}
    </Mutation>


  </StyledTransportationDetail>
);


TransportationDetailView.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  translations: object.isRequired,
  rsTransportationConfig: object.isRequired,
  fromClearing: bool,
  detailForm: object,
  // methods
  onToggle: func.isRequired,
  onChangeForm: func,
  onEdit: func,
  onEditError: func,
  onEditComplete: func,
};

TransportationDetailView.defaultProps = {
  fromClearing: false,
  detailForm: {},
  onChangeForm: null,
  onEdit: () => {},
  onEditError: () => {},
  onEditComplete: () => {},
};


export default TransportationDetailView;
