import React from 'react';
import { string, } from 'prop-types';

import { QUERY_GATE_RECORDS, } from '../../gql/queries';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import TableLogic from './TableLogic';
import { setDateTodayMaxTime, setDateTodayMinTime, } from '../../../../logic/date';


const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    timeFrom: null,
    timeTo: null,
    registrationNumber: '',
    vehicleGroupId: null,
    driverName: '',
    driverPhoneNumber: '',
  },
};


const TabHistory = ({
  languageId,
  ...rest
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_GATE_RECORDS}
    filterToVariables={(filter) => ({
      ...filter,
      languageId,
      section: 'history',
      params: {
        timeFrom: filter.params.timeFrom
          ? setDateTodayMinTime(filter.params.timeFrom)
          : null,
        timeTo: filter.params.timeTo
          ? setDateTodayMaxTime(filter.params.timeTo)
          : null,
        vehicleGroupId: filter.params.vehicleGroupId
          ? filter.params.vehicleGroupId.id
          : null,
        registrationNumber: filter.params.registrationNumber
          ? filter.params.registrationNumber
          : null,
        gateType: filter.params.gateType
          ? filter.params.gateType.id
          : null,
        driverName: filter.params.driverName
          ? filter.params.driverName
          : null,
        driverPhoneNumber: filter.params.driverPhoneNumber
          ? filter.params.driverPhoneNumber
          : null,
      },
    })}
  >
    {(tableData) => (
      <TableLogic
        languageId={languageId}
        {...tableData}
        {...rest}
      />
    )}
  </FilteredTable>
);


TabHistory.propTypes = {
  languageId: string.isRequired,
};


export default TabHistory;
