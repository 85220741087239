import styled from 'styled-components';


const StyledPagination = styled.div`
  margin-top: 1.5rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  button {
    margin: 0 0.125rem;
  }

  .pagination--separator {
    font-size: 1.5rem;
    line-height: 0.5;
    vertical-align: middle;
  }

  .pagination--actual {
    cursor: initial;
  }

  .pagination--actual:focus, .pagination--actual:active, .pagination--actual:hover {
    box-shadow: none;
  }

  .limits--select {
    margin: 0 0.125rem;
    display: inline-block;
    width: 6rem;
  }
`;


export default StyledPagination;
