import {
  phoneNumberRegex,
  floatingPointNumberRegex,
} from 'sofair-form-validation';

import { MESSAGES, } from '../../globals';


export const regexEmail = {
  name: 'email',
  expression: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  code: MESSAGES.REGEX_EMAIL,
  params: '',
};


export const regexPhone = {
  name: 'phone',
  expression: phoneNumberRegex,
  code: MESSAGES.REGEX_PHONE_NUMBER,
  params: '',
};


export const regexFloat = {
  name: 'float',
  expression: floatingPointNumberRegex,
  code: MESSAGES.REGEX_FLOAT,
  params: '',
};
