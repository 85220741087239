import React from 'react';
import { func, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { QUERY_RS_TRANSPORTATION_CONFIG, QUERY_TRANSPORTATION_DETAIL, } from '../../gql/queries';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import TransportationEditLogic from './TransportationEditLogic';


const TransportationEdit = ({
  // data
  transportationId,
  languageId,
  translations,
  // methods
  onToggle,
  onDeleted,
  onEdited,
}) => (
  <Modal
    isOpen
    title={translations.transportation.editModalTransportationTitle}
    size="XL"
    onClose={onToggle}
    disablePadding
  >
    <Query
      query={QUERY_TRANSPORTATION_DETAIL}
      fetchPolicy="network-only"
      variables={{
        id: transportationId,
        languageId,
      }}
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <div className="app--padding">
              <PartLoading />
            </div>
          );
        }
        if (error || !data.fetchOneTransportation) {
          return (
            <div className="app--padding">
              <PartError error={error} />
            </div>
          );
        }
        return (
          <Query query={QUERY_RS_TRANSPORTATION_CONFIG}>
            {(queryRsTransportationConfigResult) => {
              if (queryRsTransportationConfigResult.loading
                || queryRsTransportationConfigResult.error
              ) {
                return (<div />);
              }
              return (
                <TransportationEditLogic
                  // data
                  id={transportationId}
                  data={data.fetchOneTransportation}
                  translations={translations}
                  languageId={languageId}
                  rsTransportationConfig={queryRsTransportationConfigResult.data.fetchConfigForTransportation}
                  // methods
                  onToggle={onToggle}
                  onDeleted={onDeleted}
                  onEdited={onEdited}
                />
              );
            }}
          </Query>
        );
      }}
    </Query>
  </Modal>
);


TransportationEdit.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onDeleted: func,
  onEdited: func,
};

TransportationEdit.defaultProps = {
  onDeleted: undefined,
  onEdited: undefined,
};


export default withTranslations(TRANSLATIONS_TRANSPORTATION)(TransportationEdit);
