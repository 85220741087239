import React, { Component, } from 'react';
import {
  shape, arrayOf, string, func, number, object,
} from 'prop-types';
import styled from 'styled-components';

import { Mutation, } from 'react-apollo';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { computeAvailableTimes, setTimeFromStringToDate, } from './utils';
import Button from '../../../../atoms/Button/Button';
import BlockingTable from './BlockingTable';
import Loader from '../../../../atoms/Loader/Loader';
import { MUTATION_CREATE_PLATFORM_BLOCK, } from '../../gql/mutations';


const StyledBlockingForm = styled.div`
  .select-div {
    display: inline-block;
    padding: 0.5rem;
    
    span {
      font-weight: bold;
    }
  }
  
  .platform-select {
    width: 100%;
  }
  
  .time-select {
    width: 50%;
  }
  
  .btn-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;


class BlockingForm extends Component {
  constructor(props) {
    super(props);

    const {
      platforms, workingTimeStart, workingTimeEnd, timeStepInMinutes,
    } = props;

    const availableTimes = computeAvailableTimes(
      workingTimeStart, workingTimeEnd, timeStepInMinutes,
    );

    this.state = {
      availableTimes,
      availableTimesForStart: availableTimes.slice(0, -1),
      availableTimesForEnd: availableTimes.slice(1),
      selectedStart: availableTimes[0],
      selectedEnd: availableTimes[availableTimes.length - 1],
      selectedPlatform: platforms[0],
    };
  }

  /**
   * Updates state if props change.
   */
  componentDidUpdate(prevProps) {
    const {
      workingTimeStart, workingTimeEnd, timeStepInMinutes, platforms,
    } = this.props;

    if (
      workingTimeStart !== prevProps.workingTimeStart
      || workingTimeEnd !== prevProps.workingTimeEnd
      || timeStepInMinutes !== prevProps.timeStepInMinutes
    ) {
      const availableTimes = computeAvailableTimes(
        workingTimeStart, workingTimeEnd, timeStepInMinutes,
      );

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        availableTimes,
        availableTimesForStart: availableTimes.slice(0, -1),
        availableTimesForEnd: availableTimes.slice(1),
        selectedStart: availableTimes[0],
        selectedEnd: availableTimes[availableTimes.length - 1],
      });
    }

    if (platforms !== prevProps.platforms) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedPlatform: platforms[0],
      });
    }
  }

  handleChangePlatform = (platform) => {
    this.setState({ selectedPlatform: platform, });
  }

  handleChangeStart = (start) => {
    const { availableTimes, selectedEnd, } = this.state;

    this.setState({
      selectedStart: start,
      availableTimesForEnd: availableTimes.filter((at) => at.id > start.id),
    });

    if (selectedEnd.id <= start.id) {
      this.setState({
        selectedEnd: availableTimes[availableTimes.length - 1],
      });
    }
  }

  handleChangeEnd = (end) => {
    this.setState({ selectedEnd: end, });
  }

  handleSubmitClick = (createMutation) => {
    const { selectedDate, } = this.props;
    const { selectedPlatform, selectedStart, selectedEnd, } = this.state;

    if (selectedPlatform.id && selectedStart.time && selectedEnd.time) {
      const platformId = selectedPlatform.id;
      const from = setTimeFromStringToDate(selectedDate, selectedStart.time);
      const to = setTimeFromStringToDate(selectedDate, selectedEnd.time);

      createMutation({ variables: { platformId, from, to, }, });
    }
  };

  handleNewPlatformBlockCreated = (data, platform, date, start, end) => {
    const { onPlatformBlockCreated, } = this.props;

    const from = setTimeFromStringToDate(date, start.time);
    const to = setTimeFromStringToDate(date, end.time);

    onPlatformBlockCreated(data.createPlatformBlock, platform.id, from, to);
  }

  render() {
    const {
      platforms, platformBlocks, onDeletePlatformBlock, selectedDate, translations,
    } = this.props;
    const {
      availableTimesForStart, availableTimesForEnd, selectedPlatform, selectedStart, selectedEnd,
    } = this.state;

    return (
      <StyledBlockingForm>
        <div className="select-div platform-select">
          <span>
            {translations.rs.platformBlocks.platformSelect}
          </span>
          <InputSelect
            value={selectedPlatform}
            options={platforms}
            onChange={this.handleChangePlatform}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            size="sm"
          />
        </div>

        <div className="select-div time-select">
          <span>
            {translations.rs.platformBlocks.blockFrom}
          </span>
          <InputSelect
            value={selectedStart}
            options={availableTimesForStart}
            onChange={this.handleChangeStart}
            getOptionLabel={(option) => option.time}
            getOptionValue={(option) => option.id}
            size="sm"
          />
        </div>

        <div className="select-div time-select">
          <span>
            {translations.rs.platformBlocks.blockTo}
          </span>
          <InputSelect
            value={selectedEnd}
            options={availableTimesForEnd}
            onChange={this.handleChangeEnd}
            getOptionLabel={(option) => option.time}
            getOptionValue={(option) => option.id}
            size="sm"
          />
        </div>

        <div className="btn-div">
          <Mutation
            mutation={MUTATION_CREATE_PLATFORM_BLOCK}
            onCompleted={(data) => {
              this.handleNewPlatformBlockCreated(
                data, selectedPlatform, selectedDate, selectedStart, selectedEnd,
              );
            }}
            // onError={onCreateError}
          >
            {(createMutation, { loading, }) => (
              <Button
                onClick={() => this.handleSubmitClick(createMutation)}
                color="success"
                size="md"
              >
                {loading ? <Loader /> : (translations.rs.platformBlocks.blockButtonText)}
              </Button>
            )}
          </Mutation>
        </div>

        <BlockingTable
          platformBlocks={platformBlocks}
          onDeletePlatformBlock={onDeletePlatformBlock}
        />
      </StyledBlockingForm>
    );
  }
}

BlockingForm.propTypes = {
  selectedDate: object.isRequired,
  platforms: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
  })).isRequired,
  workingTimeStart: string.isRequired,
  workingTimeEnd: string.isRequired,
  timeStepInMinutes: number.isRequired,
  platformBlocks: arrayOf(shape({
    id: number.isRequired,
    platformId: number.isRequired,
    platformName: string.isRequired,
    from: object.isRequired,
    to: object.isRequired,
  })).isRequired,
  onPlatformBlockCreated: func.isRequired,
  onDeletePlatformBlock: func.isRequired,
  translations: object.isRequired,
};

export default BlockingForm;
