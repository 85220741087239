export const isValidArray = (array) => array && Array.isArray(array);

export const unique = (array, equality = (a, b) => a === b) => array.filter((item, index, arr) => {
  const size = arr.length;

  for (let i = 0; i < size; i++) {
    if (equality(item, arr[i])) {
      return i === index;
    }
  }

  return true;
});
