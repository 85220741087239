import React, { Component, } from 'react';
import { func, object, shape, string, } from 'prop-types';

import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeFormValidations,
} from '../../../../../logic/form/common';
import { finishFormStructure, } from '../forms';
import { pipe, } from '../../../../../logic/utils';
import { withErrors, } from '../../../../../logic/errorManager/withErrors';
import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalFinishView from './ModalFinishView';


class ModalFinish extends Component {
  state = {
    finishForm: { ...finishFormStructure, },
  }


  handleChangeFinishForm = (name, value) => {
    const { finishForm, } = this.state;

    switch (name) {
      case 'manualRedirect': {
        const formWithNewValidation = changeFormValidations(
          finishForm,
          [
            {
              name: 'stand',
              validation: { required: !!value, },
            },
          ],
        );

        this.setState({
          finishForm: changeAndValidateInput(formWithNewValidation, name, value),
        });
        break;
      }

      default: {
        this.setState({
          finishForm: changeAndValidateInput(finishForm, name, value),
        });
        break;
      }
    }
  }


  /**
   * Finish / Forward
   */
  handleAction = (mutationAction) => {
    const {
      finishForm,
    } = this.state;

    const newDetailForm = validateAndMergeWholeForm(finishForm);

    // check form
    if (!newDetailForm.isValid) {
      this.setState({
        finishForm: newDetailForm,
      });

    // mutate
    } else if (finishForm.values.solve) {
      this.mutationSolve(mutationAction);
    } else {
      this.mutationFinish(mutationAction);
    }
  }


  /**
   * Forward
   */
  mutationSolve = (mutationAction) => {
    const {
      finishForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const standsId = values.stand === null ? undefined : values.stand.id;

    mutationAction({
      variables: {
        platformId,
        transportationId,
        standId: values.manualRedirect ? standsId : undefined,
      },
    });
  }


  /**
   * Finish
   */
  mutationFinish = (mutationAction) => {
    const {
      finishForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const standsId = values.stand === null ? undefined : values.stand.id;

    mutationAction({
      variables: {
        platformId,
        transportationId,
        standId: values.manualRedirect ? standsId : undefined,
      },
    });
  }

  /**
   * Finish / Forward - Completed
   */
  handleActionCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleFinishSuccess,
    });
  }


  /**
   * Finish / Forward - Error
   */
  handleActionError = (graphQLError) => {
    const { translations, translateGraphQLError, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleFinishError,
      text: translateGraphQLError(graphQLError),
    });
  }


  render() {
    const { finishForm, } = this.state;
    const {
      // data
      transportationId,
      languageId,
      translations,
      transportation,
      // methods
      onToggle,
      onDetail,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.titleModalFinish}
        disablePadding
      >
        <ModalFinishView
          // data
          transportationId={transportationId}
          transportation={transportation}
          finishForm={finishForm}
          languageId={languageId}
          translations={translations}
          // methods
          onToggle={onToggle}
          onDetail={onDetail}
          onChangeForm={this.handleChangeFinishForm}
          onAction={this.handleAction}
          onActionCompleted={this.handleActionCompleted}
          onActionError={this.handleActionError}
        />
      </Modal>
    );
  }
}


ModalFinish.propTypes = {
  // data
  platformId: string.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  transportation: object.isRequired,
  // methods
  onToggle: func.isRequired,
  translateGraphQLError: func.isRequired,
  addNotification: func.isRequired,
  onDetail: func.isRequired,
};


export default pipe(
  withErrors,
  withNotifications,
)(ModalFinish);
