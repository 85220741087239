import React, { Component, } from 'react';
import { v4, } from 'uuid';
import { func, object, string, } from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../../../logic/utils';
import { RESERVATION_TYPES, } from '../../../../globals';
import { addHoursToDate, clearSecondsAndMilliseconds, } from '../../../../logic/date';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeFormValidations,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeMultipleValues,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import {
  getReservationTimeToByCarQuantity,
  getReservationTypesOptions, getReservationTypesOptionsByTransporationType,
  parseCreateTransportationMutationVariables,
} from '../utils';
import { initCreateTransportationForm, initTransportationReservationForm, customValidationReservationForm, } from '../forms/structure';
import { QUERY_ALL_COMPANIES, } from '../../gql/queries';
import CompanyCreate from '../../../Directory/components/CompanyCreate/CompanyCreate';
import TransportationCreateView from './TransportationCreateView';
import { changeArrayElement, removeArrayElement, } from './utils';


class TransportationCreateLogic extends Component {
  constructor(props) {
    super(props);

    const { translations, resources, } = props;
    this.clickedExtraCreateBtn = null;
    const defaultReservationForm = this.handleInitReservationForm();

    this.state = {
      reservationForms: [ defaultReservationForm, ],
      detailForm: initCreateTransportationForm,
      options: {
        reservationTypes: getReservationTypesOptions(
          translations,
          !!resources.create_transportation_time_window
        ),
        carQuantity: [ ...Array(10).keys(), ].map((n) => ({ id: `${n + 1}`, name: `${n + 1}`, })),
        transportationTypeForRS: '1',
      },
      modalNewCompany: {
        isOpen: false,
        presetValues: null,
      },
    };
  }


  /**
   * Form - onChange
   */
  handleChangeReservationForm = (index, name, value) => {
    const { reservationForms, options, } = this.state;

    if (reservationForms.length < 0 || reservationForms.length <= index) return;

    const form = reservationForms[index];

    switch (name) {
      // Transportation Type - reset Platform
      case 'resTransportationType': {
        const { translations, resources, } = this.props;
        const reservationTypes = getReservationTypesOptionsByTransporationType(
          value ? value.id : null,
          options.transportationTypeForRS,
          translations,
          !!resources.create_transportation_time_window,
        );
        const newReservationForm = changeMultipleValues(form, [
          {
            name,
            value,
          },
          {
            name: 'resPlatform',
            value: null,
          },
          {
            name: 'resReservationType',
            value: null,
          },
          {
            name: 'carQuantity',
            value: null,
          },
          {
            name: 'carCompany',
            value: null,
          },
          {
            name: 'destination',
            value: null,
          },
        ]);
        this.setState((prevState) => ({
          reservationForms: changeArrayElement(reservationForms, index, newReservationForm),
          options: {
            ...prevState.options,
            reservationTypes,
          },
        }));
        break;
      }

      // Reservation Type - change validation
      case 'resReservationType': {
        const resTypeDay = value && value.id === RESERVATION_TYPES.DAY;
        const resTypeInterval = value && (
          value.id === RESERVATION_TYPES.INTERVAL
          || value.id === RESERVATION_TYPES.INTERVAL_PLATFORM
        );
        const resTypeIntervalPlatform = value && value.id === RESERVATION_TYPES.INTERVAL_PLATFORM;

        const newForm = changeFormValidations(
          form,
          [
            {
              name: 'resPlatform',
              validation: { required: resTypeIntervalPlatform, },
            },
            {
              name: 'resDate',
              validation: { required: resTypeDay, },
            },
            {
              name: 'resTimeFrom',
              validation: { required: resTypeInterval, },
            },
            {
              name: 'resTimeTo',
              validation: { required: resTypeInterval, },
            },
          ],
        );

        const newReservationForm = changeAndValidateInput(newForm, name, value);
        this.setState({
          reservationForms: changeArrayElement(reservationForms, index, newReservationForm),
        });
        break;
      }

      case 'carQuantity':
      case 'resTimeFrom': {
        const newReservationForm = changeAndValidateInput(form, name, value);
        this.setState({
          reservationForms: changeArrayElement(reservationForms, index, newReservationForm),
        });

        if (newReservationForm.values.resTransportationType.id
          === options.transportationTypeForRS
        ) {
          this.computeReservationTimeTo(index, newReservationForm, reservationForms);
        }
        break;
      }

      // Default
      default: {
        const newReservationForm = changeAndValidateInput(form, name, value);
        this.setState({
          reservationForms: changeArrayElement(reservationForms, index, newReservationForm),
        });
        break;
      }
    }
  }

  computeReservationTimeTo = (index, reservationForm, reservationForms) => {
    if (!reservationForm.values.carQuantity) {
      return;
    }
    const { rsTransportationConfig, } = this.props;

    const newReservationForm = changeAndValidateInput(reservationForm, 'resTimeTo',
      getReservationTimeToByCarQuantity(
        parseInt(reservationForm.values.carQuantity.id, 10),
        rsTransportationConfig.unloadDurations,
        reservationForm.values.resTimeFrom,
      ));
    this.setState({
      reservationForms: changeArrayElement(reservationForms, index, newReservationForm),
    });
  }

  handleChangeDetailForm = (name, value) => {
    const { detailForm, } = this.state;
    const newDetailForm = changeAndValidateInput(detailForm, name, value);
    this.setState({ detailForm: newDetailForm, });
  }


  /**
   * Reservation form Init/Add/Remove
   */
  handleInitReservationForm = () => {
    const now = clearSecondsAndMilliseconds(new Date());
    const delayed = addHoursToDate(now, 2);

    return {
      ...initTransportationReservationForm,
      formId: v4(),
      values: {
        ...initTransportationReservationForm.values,
        resDate: now,
        resTimeFrom: now,
        resTimeTo: delayed,
      },
    };
  }

  handleAddReservationForm = () => {
    const newReservationForm = this.handleInitReservationForm();

    this.setState((prevState) => ({
      reservationForms: [
        ...prevState.reservationForms,
        newReservationForm,
      ],
    }));
  }

  handleRemoveReservationForm = (index) => {
    this.setState((prevState) => ({
      reservationForms: removeArrayElement(prevState.reservationForms, index),
    }));
  }


  /**
   * Fill form values
   */
  handleFillFormValues = (newValues) => {
    const { detailForm, } = this.state;

    const newDetailForm = changeMultipleValues(detailForm, newValues);
    this.setState({ detailForm: newDetailForm, });
  }


  /**
   * Modal New Company - Close
   */
  closeModalAddNewCompany = () => {
    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: false,
        presetValues: null,
      },
    }));
  }

  /**
   * Modal New Company - Open
   */
  handleAddNewCompany = () => {
    const { detailForm: { values, }, } = this.state;

    const parsedCompanyData = {
      name: values.compName,
      identificationNumber: values.compIdentificationNumber,
      vatNumber: values.compVatNumber,
      street: values.compStreet,
      city: values.compCity,
      zip: values.compZip,
      state: values.compState,
      info: values.compInfo,
      contactPerson: values.compContactPerson,
      email: values.compEmail,
      phoneNumber: values.compPhoneNumber,
    };

    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: true,
        presetValues: parsedCompanyData,
      },
    }));
  }


  /**
   * Modal New Company - Company Added
   */
  handleAddNewCompanyCompleted = () => {
    const { client, } = this.props;

    client.query({
      query: QUERY_ALL_COMPANIES,
      fetchPolicy: 'network-only',
    });
  }


  /**
   * onCreate - Standard btn
   */
  handleCreateExtra = (createMutation) => {
    this.clickedExtraCreateBtn = true;

    this.handleCreate(createMutation);
  }

  /**
   * onCreate - Extra Btn
   */
  handleCreateStandard = (createMutation) => {
    this.clickedExtraCreateBtn = false;

    this.handleCreate(createMutation);
  }


  /**
   * onCreate
   */
  handleCreate = (createMutation) => {
    const {
      detailForm, reservationForms, detailForm: { values, }, options,
    } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm);
    const newReservationForms = reservationForms.map(
      (item) => validateAndMergeWholeForm(
        item,
        customValidationReservationForm(options.transportationTypeForRS)
      )
    );
    const isReservationFormsValid = !newReservationForms.some((item) => item.isValid === false);

    if (!newDetailForm.isValid || !isReservationFormsValid) {
      this.setState({
        detailForm: newDetailForm,
        reservationForms: newReservationForms,
      });
    } else {
      createMutation({
        variables: parseCreateTransportationMutationVariables(newReservationForms, values),
      });
    }
  }


  /**
   * onCreate - Completed
   */
  handleCreateComplete = (data) => {
    const {
      translations,
      onToggle,
      addNotification,
      onCreated,
      onCreatedExtraCreateBtn,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });

    if (onCreated) onCreated(data);
    if (this.clickedExtraCreateBtn && onCreatedExtraCreateBtn) onCreatedExtraCreateBtn(data);
  }


  /**
   * onCreate - Error
   */
  handleCreateError = (mutationError) => {
    try {
      const { reservationForms, detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const { reservations, ...errorsDetailForm } = extensions.exception.data;
              this.setState({
                // merge detailForm errors
                detailForm: mergeValidationObjectIntoForm(detailForm, errorsDetailForm),
                // merge reservationForms errors
                reservationForms: reservationForms.map((item, index) => {
                  if (reservations && index <= reservations.length) {
                    return mergeValidationObjectIntoForm(item, reservations[index]);
                  }
                  return item;
                }),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const {
      reservationForms,
      detailForm,
      options,
      modalNewCompany,
    } = this.state;
    const {
      extraCreateButton, languageId, translations, onToggle, rsTransportationConfig,
    } = this.props;

    return (
      <>
        {modalNewCompany.isOpen && (
          <CompanyCreate
            // data
            modalData={{ isOpen: true, }}
            presetValues={modalNewCompany.presetValues}
            // methods
            onToggle={this.closeModalAddNewCompany}
            onCreated={this.handleAddNewCompanyCompleted}
          />
        )}

        <TransportationCreateView
          // data
          reservationForms={reservationForms}
          detailForm={detailForm}
          options={options}
          extraCreateButton={extraCreateButton}
          languageId={languageId}
          translations={translations}
          rsTransportationConfig={rsTransportationConfig}
          // methods
          onToggle={onToggle}
          onChangeReservationForm={this.handleChangeReservationForm}
          onChangeDetailForm={this.handleChangeDetailForm}
          onAddReservationForm={this.handleAddReservationForm}
          onRemoveReservationForm={this.handleRemoveReservationForm}
          onCreateExtra={this.handleCreateExtra}
          onCreateStandard={this.handleCreateStandard}
          onCreateComplete={this.handleCreateComplete}
          onCreateError={this.handleCreateError}
          onFillFormValues={this.handleFillFormValues}
          onAddNewCompany={this.handleAddNewCompany}
        />
      </>
    );
  }
}


TransportationCreateLogic.propTypes = {
  // data
  extraCreateButton: object,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  client: object.isRequired,
  rsTransportationConfig: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onCreated: func,
  onCreatedExtraCreateBtn: func,
};

TransportationCreateLogic.defaultProps = {
  extraCreateButton: undefined,
  onCreated: undefined,
  onCreatedExtraCreateBtn: undefined,
};


export default pipe(
  withNotifications,
  withApollo,
)(TransportationCreateLogic);
