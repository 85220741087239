import gql from 'graphql-tag';


export const QUERY_ERRORS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          errors {
            CUSTOM_ERROR_FROM_GREATER_THAN_TO
            REGEX_EMAIL
            REGEX_PHONE_NUMBER
            REGEX_FLOAT
            RESERVATION_FULL
            NEXT_STAND_CAPACITY_FULL
            INPUT_UNIQUE
            BAD_CREDENTIALS
            INPUT_TEXT_REQUIRED
            INPUT_TEXT_MIN
            INPUT_TEXT_MAX
            INPUT_NUMBER_REQUIRED
            INPUT_NUMBER_MIN
            INPUT_NUMBER_MAX
            INPUT_SELECT_REQUIRED
            INPUT_MULTISELECT_REQUIRED
            INPUT_MULTISELECT_MIN
            INPUT_MULTISELECT_MAX
            INPUT_CHECKBOX_REQUIRED
            INPUT_RADIO_REQUIRED
            NOT_CONNECTED
            INVALID_FORM
            SOMETHING_HAPPENED
            NEXT_STAND_FULL
            USER_BLOCKED
            UNAUTHORIZED
            NO_ALREADY_USED
            STAND_NO_CONFIGURATION
            IDENTIFICATION_NUMBER_ALREADY_USED
            EMPTY_QUEUE
            NEXT_STAND_FULL
            BAD_PLATFORM
            PLATFORM_FULL_CAPACITY
            TRANSPORTATION_ON_BLACKLIST
            UNABLE_TO_START
          }
        }
      }
    }
  }
`;
