import React from 'react';
import {
  string, object, func, number,
} from 'prop-types';

import PlatformSections from './PlatformSections';
import PlatformPanel from './PlatformPanel';


const convertServiceTypeToName = (serviceType) => {
  const index = parseInt(serviceType, 10);

  switch (index) {
    case 1: return 'unload';
    case 2: return 'load';
    case 3: return 'mixed';
    default: return '';
  }
};

const PlatformView = ({
  // data
  platform,
  occupied,
  languageId,
  translations,
  translationsCommon,
  resources,
  // functions
  onSetPlatformState,
  onSetPlatformStateCompleted,
  onSetPlatformStateError,
  onCallTransportation,
  onCallTransportationCompleted,
  onCallTransportationError,
}) => (
  <div className="storekeeperPlatform--platform">

    {platform.serviceType && (
      <div className="storekeeperPlatform--platform-type-panel">
        <div className={`storekeeperPlatform--platform-type-panel-column storekeeperPlatform--platform-type-panel-column-${convertServiceTypeToName(platform.serviceType)}`}></div>
        <div className={`storekeeperPlatform--platform-type-panel-column storekeeperPlatform--platform-type-panel-column-${convertServiceTypeToName(platform.serviceType)}`}></div>
      </div>
    )}

    <PlatformPanel
      // data
      platform={platform}
      occupied={occupied}
      languageId={languageId}
      translations={translations}
      resources={resources}
      // functions
      onSetPlatformState={onSetPlatformState}
      onSetPlatformStateCompleted={onSetPlatformStateCompleted}
      onSetPlatformStateError={onSetPlatformStateError}
    />

    <PlatformSections
      // data
      platform={platform}
      occupied={occupied}
      languageId={languageId}
      translations={translations}
      translationsCommon={translationsCommon}
      resources={resources}
      // functions
      onCallTransportation={onCallTransportation}
      onCallTransportationCompleted={onCallTransportationCompleted}
      onCallTransportationError={onCallTransportationError}
    />

  </div>
);


PlatformView.propTypes = {
  platform: object.isRequired,
  occupied: number.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  resources: object.isRequired,
  onSetPlatformState: func.isRequired,
  onSetPlatformStateCompleted: func.isRequired,
  onSetPlatformStateError: func.isRequired,
  onCallTransportation: func.isRequired,
  onCallTransportationCompleted: func.isRequired,
  onCallTransportationError: func.isRequired,
};


export default PlatformView;
