import styled from 'styled-components';


const StyledQueueList = styled.ul`
  padding: 0;

  .queueList--item {
    display: flex;
    border: 1px solid ${(p) => p.theme.grey.t200};
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
    height: 5rem;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    background: ${(p) => p.theme.grey.t50};
  }

  .queueList--item-col1 {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
  }

  .queueList--item-col2 {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-align: center;
    color: ${(p) => p.theme.grey.t700};
  }

  .queueList--item-col3 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: center;
  }

  .queueList--item-btn {
    width: 5rem;
  }

  .queueList--item-btn-detail {
    margin-top: 0.25rem;
  }

  .queueList--item-text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }

  .queueList--item-text-no {
    font-weight: bold;
  }

  .queueList--item-text-rest {
    font-size: 0.8rem;
  }
  
  .queueList--item-dot {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: text-bottom;
  }

  .queueList--item-dot-unload {
    background-color: #A8DF24;
  }

  .queueList--item-dot-load {
    background-color: #822C30;
  }

  .queueList--item-dot-mixed {
    background: linear-gradient(90deg, rgba(168,223,36,1) 40%, rgba(130,44,48,1) 100%);
  }
`;


export default StyledQueueList;
