import gql from 'graphql-tag';


/**
 * Common
 */
export const QUERY_DROPDOWN = gql`
  query FetchDropDown($languageId: ID, $type: String) {
    fetchDropDown(languageId: $languageId, type: $type) {
      id
      data {
        id
        name
      }
    }
  }
`;


/**
 * Roles
 */
export const QUERY_ROLES = gql`
  query FetchRoles {
    fetchRoles {
      id
      name
    }
  }
`;

export const QUERY_ROLE = gql`
  query FetchRole($roleId: ID, $languageId: ID) {
    fetchRole(roleId: $roleId, languageId: $languageId) {
      id
      name
      resources {
        id
        constraint
      }
    }
  }
`;

export const QUERY_RESOURCES = gql`
  query FetchResources {
    fetchResources {
      id
      name
      resources {
        id
        name
        rn
        constraint
        dropDownType
      }
    }
  }
`;


/**
 * Users
 */
export const QUERY_USERS = gql`
  query FetchUsers {
    fetchUsers {
      id
      username
      block
      email
      roles {
        id
        name
      }
    }
  }
`;

export const QUERY_USER = gql`
  query FetchUser($id: ID) {
    fetchUser(id: $id) {
      id
      username
      pin
      chip
      email
      roles {
        id
        name
      }
    }
  }
`;

/**
 * SMS
 */
export const QUERY_SMS_TEMPLATE_DETAIL = gql`
  query FetchOneSmsTemplate($id: ID!) {
    fetchOneSmsTemplate(id: $id) {
      id
      typeTranslation
      languageCode
      text
      placeholders {
        id
        placeholder
        placeholderTranslation
      }
    }
  }
`;

export const QUERY_SMS_TEMPLATES = gql`
  query FilterSmsTemplates(
    $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: smsTemplateInputFilterParams
  ) {
    filterSmsTemplates(
      limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params
    ) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
          typeTranslation
          languageCode
        }
      }
      rows {
        id
        typeTranslation
        languageCode
      }
    }
  }
`;
