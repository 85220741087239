import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { QUERY_GATEHOUSE_TRANSLATIONS, } from '../gql/translations';
import TabHistory from '../components/TabHistory/TabHistory';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import GatehouseView from '../components/GatehouseView';
import { withTranslations, } from '../../../logic/translations/withTranslations';

export const GATEHOUSE_TABS = {
  HISTORY: 'history',
};

class GatehousePage extends Component {
  state = {
    activeTab: GATEHOUSE_TABS.HISTORY,
  };

  handleChangePage = (page) => {
    this.setState({ activeTab: page, });
  }

  render() {
    const { activeTab, } = this.state;
    const { translations, languageId, } = this.props;

    return (
      <PageFullScreen>
        <GatehouseView
          activeTab={activeTab}
          translations={translations}
          // methods
          onChangePage={this.handleChangePage}
        >
          {activeTab === GATEHOUSE_TABS.HISTORY && (
            <TabHistory
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
        </GatehouseView>
      </PageFullScreen>
    );
  }
}

GatehousePage.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
};

export default withTranslations(QUERY_GATEHOUSE_TRANSLATIONS)(GatehousePage);
