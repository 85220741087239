import React, { Fragment, } from 'react';
import { arrayOf, func, object, shape, string, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_UPDATE_RS_USER, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import UserForm from '../UserForm/UserForm';
import FormBackground from '../../../../components/Form/FormBackground';


const UserUpdateView = ({
  // data
  detailForm,
  translations,
  languages,
  // methods
  onChange,
  onToggle,
  onUpdate,
  onUpdateCompleted,
  onUpdateError,
}) => (
  <FormBackground>
    <UserForm
      {...detailForm}
      translations={translations}
      onChange={onChange}
      languages={languages}
    />

    <Mutation
      mutation={MUTATION_UPDATE_RS_USER}
      onCompleted={onUpdateCompleted}
      onError={onUpdateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                type="button"
                shape="outline"
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                type="button"
                color="success"
                onClick={() => onUpdate(mutate)}
                disabled={!detailForm.isValid}
                isLoading={loading}
              >
                {translations.common.update}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


UserUpdateView.propTypes = {
  translations: object.isRequired,
  detailForm: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onUpdate: func.isRequired,
  onUpdateError: func.isRequired,
  onUpdateCompleted: func.isRequired,
  languages: arrayOf(shape({
    id: string,
    code: string,
    name: string,
  })).isRequired,
};


export default UserUpdateView;
