import React, { Component, } from 'react';
import {
  string, object, func, shape,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import Pagination from '../../../../components/Pagination/Pagination';
import TableView from './TableView';
import RecordView from '../RecordView/RecordView';
import YesNoModal from '../../../../components/modal/YesNoModal';
import { MUTATION_DELETE_GATEHOUSE_RECORDS, } from '../../gql/mutations';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { pipe, } from '../../../../logic/utils';


class TableLogic extends Component {
  state = {
    detailRecordModal: {
      isOpen: false,
      id: null,
    },
    deleteRecordsModal: {
      isOpen: false,
      ids: null,
    },
    selectedItems: [],
    allItemsSelected: false,
  }

  handleToggleRecordDetail = (id = null) => {
    this.setState((prevState) => ({
      detailRecordModal: {
        isOpen: !prevState.detailRecordModal.isOpen,
        id,
      },
    }));
  }

  handleToggleDeleteRecordsModal = (ids = null) => {
    const { selectedItems, } = this.state;

    this.setState((prevState) => ({
      deleteRecordsModal: {
        ...prevState.deleteRecordsModal,
        isOpen: !prevState.deleteRecordsModal.isOpen,
        ids: ids === null ? selectedItems : ids,
      },
    }));
  }

  handleDeleteRecords = async (result) => {
    const {
      client, onChangeFilter, addNotification, translations,
    } = this.props;
    const { deleteRecordsModal, } = this.state;

    const state = {};

    if (result && Array.isArray(deleteRecordsModal.ids) && deleteRecordsModal.ids.length > 0) {
      const mutationResult = await client.mutate({
        mutation: MUTATION_DELETE_GATEHOUSE_RECORDS,
        variables: {
          ids: deleteRecordsModal.ids,
        },
      });

      if (mutationResult.data.deleteGatehouseRecords) {
        addNotification({
          status: 'success',
          title: translations.gatehouse.deleteSuccessNotificationText,
        });
        state.selectedItems = [];
        state.allItemsSelected = false;
        onChangeFilter('forceUpdate', Date.now());
      } else {
        addNotification({
          status: 'error',
          title: translations.gatehouse.deleteErrorNotificationText,
        });
      }
    }

    this.setState((prevState) => ({
      ...state,
      deleteRecordsModal: {
        ...prevState.deleteRecordsModal,
        isOpen: !prevState.deleteRecordsModal.isOpen,
        ids: null,
      },
    }));
  }

  handleSelectItem = (id, checked) => {
    const { queryData, } = this.props;
    const { selectedItems, } = this.state;

    let ids = [];

    if (checked && selectedItems.indexOf(id) === -1) {
      selectedItems.push(id);
      ids = selectedItems;
    } else if (!checked && selectedItems.indexOf(id) !== -1) {
      ids = selectedItems.filter((si) => si !== id);
    }

    this.setState(() => ({
      selectedItems: ids,
      allItemsSelected: queryData.data.filterGatehouseRecords.rows.length === ids.length,
    }));
  }

  handleToggleAllItems = (checked) => {
    const { queryData, } = this.props;

    if (checked) {
      this.setState(() => ({
        selectedItems: queryData.data.filterGatehouseRecords.rows.map((row) => row.id),
        allItemsSelected: true,
      }));
    } else {
      this.setState(() => ({
        selectedItems: [],
        allItemsSelected: false,
      }));
    }
  }

  handleFilterChange = (params, callback) => {
    const { selectedItems, } = this.state;

    if (selectedItems.length > 0) {
      this.setState(() => ({
        selectedItems: [],
        allItemsSelected: false,
      }));
    }

    callback(...params);
  }

  render() {
    const {
      detailRecordModal, deleteRecordsModal, selectedItems, allItemsSelected,
    } = this.state;
    const {
      // data
      filter,
      queryData,
      languageId,
      translations,
      // methods
      onChangeFilter,
      onChangeSort,
      onChangeParam,
    } = this.props;

    return (
      <>
        {detailRecordModal.isOpen && (
          <RecordView
            // data
            recordId={detailRecordModal.id}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleRecordDetail}
          />
        )}

        {deleteRecordsModal.isOpen && (
          <YesNoModal
            // data
            isOpen={deleteRecordsModal.isOpen}
            title={translations.common.warning}
            text={selectedItems.length > 1
              ? translations.gatehouse.deleteModalRecordsText
              : translations.gatehouse.deleteModalRecordText
            }
            // methods
            onChoose={this.handleDeleteRecords}
          />
        )}

        <TableView
          // data
          filter={filter}
          queryData={queryData}
          translations={translations}
          languageId={languageId}
          selectedItems={selectedItems}
          allItemsSelected={allItemsSelected}
          // methods
          onDetail={this.handleToggleRecordDetail}
          onDelete={this.handleToggleDeleteRecordsModal}
          onSelectItem={this.handleSelectItem}
          onToggleAllItems={this.handleToggleAllItems}
          onChangeSort={onChangeSort}
          onChangeParam={(...args) => { this.handleFilterChange(args, onChangeParam); }}
        />

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterGatehouseRecords
            ? queryData.data.filterGatehouseRecords.filter
            : undefined
          }
          onChangePage={(...args) => { this.handleFilterChange(args, onChangeFilter); }}
          limits={[ 20, 50, 100, 200, 500, ]}
        />
      </>
    );
  }
}

TableLogic.propTypes = {
  // data
  filter: object.isRequired,
  queryData: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  client: shape({
    mutate: func.isRequired,
  }).isRequired,
  // methods
  onChangeFilter: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
  addNotification: func.isRequired,
};

export default pipe(withNotifications, withApollo)(TableLogic);
