import gql from 'graphql-tag';


export const QUERY_REPORTS_CONFIG = gql`
  query GetReportsData {
    getReportsByLoggedUser
    {
      id
      name
      transportationTypeIds
      parameters
    }
    fetchAllTransportationTypes {
      id
      name
    }
  }
`;

export const QUERY_REPORT_DATA = gql`
  query Query($filter: getReportDataInputType) {
    getReportData(filter: $filter) {
      layout
      data {
        from
        to
        metrics {
          name
          value
        }
      }
    }
  }
`;

export const QUERY_REPORT_USER_GROUP_SETTINGS = gql`
  query GetReportUserGroupSettings {
    getReportUserGroups {
      id
      name
      reportIds
      userIds
      transportationTypeIds
    }
    getAllReports {
      id
      name
    }
    fetchUsersForReporting {
      id
      username
    }
    fetchAllTransportationTypes {
      id
      name
    }
  }
`;

export const QUERY_REPORT_NOTIFICATION_GROUP_SETTINGS = gql`
  query GetReportNotificationGroupSettings {
    getReportNotificationGroups {
      id
      name
      reportId
      userEmails
      transportationTypeIds
      compareInterval
      sendInterval
      sendIntervalDay
      sendIntervalHour
      parameters {
        id
        value
      }
    }
    getAllReports {
      id
      name
      parameters {
        defaultValue
        id
        label
        type
      }
    }
    fetchUsersForReporting {
      id
      username
      email
    }
    fetchAllTransportationTypes {
      id
      name
    }
  }
`;
