import gql from 'graphql-tag';


export const QUERY_GATEHOUSE_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            gatehouse
            unknown
            filter
            from
            to
            detail
            delete
            back
            yes
            no
            deleteSelected
            warning
          }
          form {
            time
            registrationNumber
            gateTypeEntrance
            gateTypeExit
            gateType
            vehicleGroupName
            note
            driver
            phoneNumber
          }
          transportation {
            thActions
            btnApplyFilter
            placeholderFilterDriver
          }
          gatehouse {
            arrival
            departure
            createRecord
            createTransportation
            confirmEntrance
            confirmEntranceModalTitle
            confirmEntranceModalText
            entranceConfirmed
            rejectEntrance
            rejectEntranceModalTitle
            rejectEntranceModalText
            entranceRejected
            transportationFromRecordCreated
            tabActual
            tabInArea
            tabHistory
            tabNotifications
            direction
            noRecords
            titleRecordEditModal
            titleBasicForm
            titleExtraForm
            titleTransportationListForm
            rnPicture
            transportationSearchLabel
            transportationSearchPlaceholder
            transportationSearchNotUsedInfo
            btnSearch
            clearancePartLabel
            clearancePartPlaceholder
            transportationListLabel
            emptyTransportationList
            deleteModalRecordTitle
            deleteModalRecordText
            deleteModalRecordsText
            deleteSuccessNotificationText
            deleteErrorNotificationText
            btnConfirmDeparture
            confirmDepartureModalTitle
            confirmDepartureModalText
            truckInAreaStatus
            truckHistoryStatus
            truckHistoryRejectedStatus
            findTransportationByTransportationNo
            findTransportationByRN
            inputSoe
            outputSoe
            clearance
            qualityControl
            findTransportationNoDirection
            findTransportationNoRNOrTransportationNo
            findTransportationNotFound
            findTransportationFound
            checkpoints
            confirmDenied
          }
        }
      }
    }
  }
`;
