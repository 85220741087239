import styled from 'styled-components';


const FlowLine = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: stretch;
  }
  
  .flowLine--left {
    width: 10rem;
    padding: 0 1rem 0.5rem 0;
    text-align: right;
  }

  .flowLine--right {
    width: 100%;
    padding: 0 0.5rem 0.5rem 1rem;
  }


  .flowLine--mid-text {
    width: 10rem;
    padding: 2px solid transparent;
    text-align: center;
  }
`;


export default FlowLine;
